'use strict';

/* exported
menuLinesCtrl
 */

function menuLinesCtrl($scope, $location, $rootScope, user) {
  $scope.filters = {};
  $scope.user = user;

  // On init if company is selected display it
  var search = $location.search();

  if (search.company) {
    $scope.filters.company = {_id: search.company};
  }

  if (search.supplier) {
    $scope.filters.supplier = {_id: search.supplier};
  }

  // On init if country is selected display it
  if (search.country) {
    $scope.filters.country = {iso2: search.country};
  }

  // On init if city is selected display it
  if (search.city) {
    $scope.filters.city = {_id: search.city};
  }

  // On init if cityB is selected display it
  if (search.cityB) {
    $scope.filters.cityB = {_id: search.cityB};
  }

  // On init if type is selected display it
  if (search.type) {
    $scope.filters.type = search.type;
  }

  // On init if published is selected display it, verify it is only 'true' or 'false'
  if (search.published && (search.published === 'true' || search.published === 'false')) {
    $scope.filters.published = search.published;
  }

  if (search.denomination) {
    $scope.filters.denomination = search.denomination;
  }

  if (search.isSupplierApi) {
    $scope.filters.isSupplierApi = search.isSupplierApi;
  }

  if (search.isPrivateTransfer) {
    $scope.filters.isPrivateTransfer = search.isPrivateTransfer;
  }

  $scope.$watch('filters.country', function(country) {
    $rootScope.$broadcast('filters.country', (country || {}).iso2);

    var search = $location.search();
    search.country = (country || {}).iso2;
    $location.search(search);
  });

  $scope.$watch('filters.city', function(city) {
    $rootScope.$broadcast('filters.city', (city || {})._id);

    var search = $location.search();
    search.city = (city || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.cityB', function(cityB) {
    $rootScope.$broadcast('filters.cityB', (cityB || {})._id);

    var search = $location.search();
    search.cityB = (cityB || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.company', function(company) {
    $rootScope.$broadcast('filters.company', (company || {})._id);

    var search = $location.search();
    search.company = (company || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.supplier', function(supplier) {
    $rootScope.$broadcast('filters.supplier', (supplier || {})._id);

    var search = $location.search();
    search.supplier = (supplier || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.denomination', function(denomination) {
    $rootScope.$broadcast('filters.denomination', (denomination));

    var search = $location.search();
    search.denomination = denomination;
    $location.search(search);
  });

  $scope.$watch('filters.isSupplierApi', function(isSupplierApi) {
    $rootScope.$broadcast('filters.isSupplierApi', (isSupplierApi));

    var search = $location.search();
    search.isSupplierApi = isSupplierApi;
    $location.search(search);
  });

  $scope.$watch('filters.isPrivateTransfer', function(isPrivateTransfer) {
    $rootScope.$broadcast('filters.isPrivateTransfer', (isPrivateTransfer));

    var search = $location.search();
    search.isPrivateTransfer = isPrivateTransfer;
    $location.search(search);
  });

  $scope.filterByType = function(type) {
    if (type === $scope.filters.type) {
      // Set off filter
      $scope.filters.type = null;
    } else {
      $scope.filters.type = type;
    }

    $rootScope.$broadcast('filters.type', $scope.filters.type);

    var search = $location.search();
    search.type = $scope.filters.type;
    $location.search(search);
  };

  $scope.filterBySupplierApi = function(isSupplierApi){
    if (isSupplierApi === $scope.filters.isSupplierApi) {
      // Set off filter
      $scope.filters.isSupplierApi = null;
    } else {
      $scope.filters.isSupplierApi = isSupplierApi;
    }

    $rootScope.$broadcast('filters.isSupplierApi', $scope.filters.isSupplierApi);

    var search = $location.search();
    search.isSupplierApi = $scope.filters.isSupplierApi;
    $location.search(search);
  };

  $scope.filterByPrivate = function(isPrivateTransfer){
    if (isPrivateTransfer === $scope.filters.isPrivateTransfer) {
      // Set off filter
      $scope.filters.isPrivateTransfer = null;
    } else {
      $scope.filters.isPrivateTransfer = isPrivateTransfer;
    }

    $rootScope.$broadcast('filters.isPrivateTransfer', $scope.filters.isPrivateTransfer);

    var search = $location.search();
    search.isPrivateTransfer = $scope.filters.isPrivateTransfer;
    $location.search(search);
  };

  $scope.filterByPublished = function(published) {
    if (published === $scope.filters.published) {
      // Set off filter
      $scope.filters.published = null;
    } else {
      $scope.filters.published = published;
    }

    $rootScope.$broadcast('filters.published', $scope.filters.published);

    var search = $location.search();
    search.published = $scope.filters.published;
    $location.search(search);
  };

  $scope.reset = function() {
    $scope.filters = {};
    $rootScope.$broadcast('resetFilters', $scope.filters);
    $location.search({});
  };
}

/**
 * Created by john on 29/9/16.
 */
'use strict';

/* exported
linesCtrl
 */

function linesCtrl($scope, lines, $location, user, moment) {
  $scope.lines = lines;
  $scope.filters = {};
  $scope.user = user;
  $scope.calcCutOffDuration = function() {
    $scope.lines.forEach(line => {
      const duration = moment.duration(line.bookBefore);
      const hours =  Math.floor(duration.asHours());
      const minutes = duration.minutes();
      line.bookBeforeDuration = hours + 'h ' + (minutes > 0 ? minutes + 'm' : '');
    });
  };

  /**
   * Paging
   */
  // On init if page is above 1
  var search = $location.search();
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.lines.meta.total;
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.lines = lines;
      $scope.calcCutOffDuration();
    });
  };

  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });


  /**
   * Filters
   */
  $scope.$on('filters.country', function(event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.city', function(event, data) {
    $scope.filters.city = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.cityB', function(event, data) {
    $scope.filters.cityB = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.company', function(event, data) {
    $scope.filters.company = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.supplier', function(event, data) {
    $scope.filters.supplier = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.denomination', function(event, data) {
    $scope.filters.denomination = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.isSupplierApi', function(event, data) {
    $scope.filters.isSupplierApi = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.isPrivateTransfer', function(event, data) {
    $scope.filters.isPrivateTransfer = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.type', function(event, data) {
    $scope.filters.type = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('filters.published', function(event, data) {
    $scope.filters.published = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.lines.getList($scope.filters).then(function(lines) {
      $scope.currentPage = 1;
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.$on('resetFilters', function(event, filters) {
    $scope.filters = Object.assign({}, filters);
    $scope.currentPage = 1;

    $scope.lines.getList(filters).then(function(lines) {
      $scope.lines = lines;
      $scope.totalItems = $scope.lines.meta.total;
      $scope.calcCutOffDuration();
    });
  });

  $scope.checkIfLineHasExtras = function (line){
    return line.extras.some(function(extra){return !extra.deleted;});
  };

  $scope.lineTypeHasTwoIcons = function (lineType) {
    return ['van-ferry', 'car-ferry', 'bus-ferry'].includes(lineType);
  };

  $scope.getLineTypeColumnStyle = function (index) {
    return {'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'border-width': '0px', 'border-top-width': index === 0 ? '0px': '1px', 'padding-top': '6px'};
  }

}

/**
 * Created by john on 26/9/16.
 */
'use strict';

/* exported
lineEditCtrl
 */

function lineEditCtrl($scope, $rootScope, $uibModal, lineEditService, ICONS, user, moment, CurrenciesService, $timeout, companyEditService, transferMappingEditService, supplierApiService) {
  $scope.lineEditService = lineEditService;
  $scope.companyEditService = companyEditService;
  $scope.lineEditService.manualBookings = $scope.lineEditService.line.manualBookings.operations || $scope.lineEditService.line.manualBookings.support;
  $scope.onManualBookingsChange = (newValue) => {
    $scope.lineEditService.manualBookings = newValue;
    if (!$scope.lineEditService.manualBookings) {
      $scope.lineEditService.line.manualBookings.operations = false;
      $scope.lineEditService.line.manualBookings.support = false;
    }
  };

  $scope.textAngularOptions = [
    ['bold', 'italics', 'underline'], 
    ['insertLink']
  ];

  $scope.featuresAndKeyDetails = {
    keyDetails: lineEditService.line.keyDetails,
    features: lineEditService.line.features
  };

  if ($scope.lineEditService.line.schedule) {
    $scope.lineEditService.refreshPriceRules();
  }

  if (!$scope.lineEditService.line.flexibleSchedule) {
    $scope.lineEditService.line.flexibleSchedule = false;
  }

  $scope.isDeAdminRole = user.additionalScopes.includes('de-admin');
  $scope.changeCurrency = (oldCurrency, newCurrency) => {
    lineEditService.line.supplierCurrency = newCurrency;

    // defaultPrice
    lineEditService.line.defaultPrice.supplierCurrency = newCurrency;
    lineEditService.line.defaultPrice.costInSupplierCurrency = CurrenciesService.convertWithFixedPrice(lineEditService.line.defaultPrice.costInSupplierCurrency || 0, oldCurrency, newCurrency);
    lineEditService.line.defaultPrice.amountInSupplierCurrency = CurrenciesService.convertWithFixedPrice(lineEditService.line.defaultPrice.amountInSupplierCurrency || 0, oldCurrency, newCurrency);

    // extras
    (lineEditService.line.extras || []).forEach(function (extra) {
      extra.price.supplierCurrency = newCurrency;
      extra.price.costInSupplierCurrency = CurrenciesService.convertWithFixedPrice(extra.price.costInSupplierCurrency || 0, oldCurrency, newCurrency);
      extra.price.amountInSupplierCurrency = CurrenciesService.convertWithFixedPrice(extra.price.amountInSupplierCurrency || 0, oldCurrency, newCurrency);
    });

    // aToBEvents
    (lineEditService.line.schedule.aToBEvents || []).forEach(function (event) {
      if (event.priceType === 'period' && event.price) {
        event.price.supplierCurrency = newCurrency;
        event.price.costInSupplierCurrency = CurrenciesService.convertWithFixedPrice(event.price.costInSupplierCurrency || 0, oldCurrency, newCurrency);
        event.price.amountInSupplierCurrency = CurrenciesService.convertWithFixedPrice(event.price.amountInSupplierCurrency || 0, oldCurrency, newCurrency);
      }
    });

    // bToAEvents
    (lineEditService.line.schedule.bToAEvents || []).forEach(function (event) {
      if (event.priceType === 'period' && event.price) {
        event.price.supplierCurrency = newCurrency;
        event.price.costInSupplierCurrency = CurrenciesService.convertWithFixedPrice(event.price.costInSupplierCurrency || 0, oldCurrency, newCurrency);
        event.price.amountInSupplierCurrency = CurrenciesService.convertWithFixedPrice(event.price.amountInSupplierCurrency || 0, oldCurrency, newCurrency);
      }
    });
  };
  $scope.$watch("lineEditService.line.company", function (newCompany, oldCompany) {
    if (newCompany && !lineEditService.line.supplier && !lineEditService.line._id) {
      $scope.changeCurrency(oldCompany ? oldCompany.currency : 'USD', newCompany.currency || 'USD');
    }
  });
  $scope.$watch("lineEditService.line.isRefundable", function (newValue, oldValue) {
    if (oldValue && !newValue) {
      lineEditService.line.isFlexibleCancellation = true;
    }
  });
  $scope.$watch("lineEditService.line.supplier", function (newSupplier, oldSupplier) {
    if (newSupplier && !lineEditService.line._id) {
      $scope.changeCurrency(oldSupplier ? oldSupplier.currency : 'USD', newSupplier.currency || 'USD');
    }
  });
  $scope.$watch("lineEditService.line.automaticApproval", function (newVal) {
    if (newVal === true) {
      $scope.lineEditService.line.flexibleSchedule = false;
    }
  });
  $scope.$watch('lineEditService.selectedApiSupplier', function() {
    lineEditService.showTransferSearchResults = false;
  });
  $scope.Itinerary = {
    add(direction) {
      const leg = {};
      if (direction === 'aToB') {
        lineEditService.line.itinerary.aToB.legs.push(leg);
        return;
      }
      lineEditService.line.itinerary.bToA.legs.push(leg);
    },
    delete(direction, index) {
      lineEditService.line.itinerary[direction].legs.splice(index, 1);
    },
    getTitle(direction) {
      if (direction === 'aToB') {
        return 'A to B';
      }
      return 'B to A';
    }
  };
  $scope.additionalServices = {
    add() {
      // create line
      if (!lineEditService.line.additionalServices) {
        lineEditService.line.additionalServices = {};
      }
      if (!lineEditService.line.additionalServices.customServices) {
        lineEditService.line.additionalServices.customServices = [];
      }
      lineEditService.line.additionalServices.customServices.push({});
    },
    delete(index) {
      lineEditService.line.additionalServices.customServices.splice(index, 1);
    }
  };
  if (!lineEditService.line.amendments || !lineEditService.line.amendments.length) {
    $scope.lineEditService.line.amendments = [{
      timeBefore: '',
      unit: 'hours',
      fee: '',
    }];
  }
  if (!lineEditService.line.cancellations || !lineEditService.line.cancellations.length) {
    $scope.lineEditService.line.cancellations = [{
      timeBefore: '',
      unit: 'hours',
      fee: '',
    }];
  }
  $scope.lineEditService.addForm($scope.lineForm);
  $scope.user = user;
  $scope.lineEditService.setActiveTab(user.shouldSeeFullAdmin ? 0 : 4);

  $scope.passengerOptions = [2, 3, 4, 5];
  $scope.isSetMinNumPassengers = !!lineEditService.line.minPassengers;

  $scope.handleChangePassengers = function () {
    $scope.isSetMinNumPassengers = !$scope.isSetMinNumPassengers;
    if (!$scope.isSetMinNumPassengers) {
      lineEditService.line.minPassengers = null;
    } else {
      $scope.lineEditService.line.minPassengers = lineEditService.line.minPassengers || 2;
    }
  }

  $scope.$watch('lineForm', function (form) {
    $scope.lineEditService.addForm(form);
  });

  $scope.directions = [{
    direction: 'aToB',
    label: 'A to B'
  }, {
    direction: 'bToA',
    label: 'B to A'
  }, {
    direction: 'both',
    label: 'Both'
  }];

  $scope.priceLevels = [{
    type: 'display_once',
    label: 'Display once'
  }, {
    type: 'display_once_muiltiply_per_direction',
    label: 'Display once and multiply per direction'
  }, {
    type: 'display_A_to_B',
    label: 'Display in A to B direction'
  }, {
    type: 'display_B_to_A',
    label: 'Display in B to A direction'
  }, {
    type: 'display_both',
    label: 'Display in both directions'
  }];

  $scope.getPriceLevels = function (extra) {
    if (extra.extraType === 'upgrade') {
      return $scope.priceLevels;
    }

    // for pickup/dropoffs remove display once and display per direction
    return $scope.priceLevels.filter(function (level) {
      return [
        'display_A_to_B',
        'display_B_to_A'
      ].includes(level.type);
    });
  };

  $scope.filterDuplicatedExtra = function (extra) {
    return !extra.duplicateOf;
  };

  $scope.filterDeletedExtra = function (extra) {
    return !extra.deleted && extra.extraType === 'upgrade';
  };

  $scope.icons = ICONS;

  $scope.loadedIcons = [];
  $scope.iconsPage = 1;
  $scope.fetch = function ($select, $event) {
    var pace = 20;
    //var lookableIcons;

    // no event means first load!
    if (!$event) {
      $scope.iconsPage = 1;
      $scope.loadedIcons = [];
    } else {
      $event.stopPropagation();
      $event.preventDefault();
      $scope.iconsPage++;
    }

    $scope.loading = true;

    var start = ($scope.iconsPage - 1) * pace;
    var end = start + pace;

    $scope.loadedIcons = $scope.loadedIcons.concat(ICONS.slice(start, end));

    $scope.loading = false;
  };

  $scope.setScheduleOutboundMethods = function (methods) {
    $scope.scheduleOutboundMethods = methods;
  };

  $scope.setScheduleInboundMethods = function (methods) {
    $scope.scheduleInboundMethods = methods;
  };

  $scope.directionMap = {
    A_TO_B: {name: 'aToB', label: 'A to B'},
    B_TO_A: {name: 'bToA', label: 'B to A'},
  };

  $scope.newExtra = {
    value: ''
  };

  $scope.addNewCancellation = function () {
    lineEditService.line.cancellations.push({
      timeBefore: null,
      unit: 'hours',
      fee: null,
    });
  };

  $scope.removeCancellation = function (index) {
    lineEditService.line.cancellations.splice(index, 1);
  };

  $scope.addNewAmendment = function () {
    lineEditService.line.amendments.push({
      timeBefore: null,
      unit: 'hours',
      fee: null,
    });
  };

  $scope.removeAmendment = function (index) {
    lineEditService.line.amendments.splice(index, 1);
  };
  $scope.changePriceLevel = function (changedExtra) {
    $scope.lineEditService.line.extras.forEach(function (extra) {
      if (changedExtra._id && changedExtra._id === extra.duplicateOf) {
        extra.deleted = changedExtra.priceLevel !== 'display_both';
      }
    });
  };

  function setMarkupAndDiscount(amount, cost) {
    var markup = parseFloat(((amount - cost) / cost * 100 + 0.001).toFixed(2));
    lineEditService.line.defaultPrice.markup = markup;
  }

  $scope.onPriceAmountChange = function (pricePath) {
    var amount = _.get($scope, pricePath + '.amount');
    var cost = _.get($scope, pricePath + '.cost');
    setMarkupAndDiscount(amount, cost);
  };

  $scope.onPriceCostChange = function (pricePath) {
    var amount = _.get($scope, pricePath + '.amount');
    var cost = _.get($scope, pricePath + '.cost');
    setMarkupAndDiscount(amount, cost);
  };

  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  function getEventList(direction) {

    var availableDepartures = lineEditService.line.schedule[direction + 'Events'].filter(function (event) {
      return event.dow && event.dow.length > 0;
    });

    availableDepartures = availableDepartures.map(function (event) {
      var startDuration = moment.duration(event.start);
      var startFormat = pad(startDuration.get('hours'), 2, 0) + ':' + pad(startDuration.get('minutes'), 2, 0);
      var eventCopy = Object.assign({}, event);
      eventCopy.start = startFormat;
      return eventCopy;
    });

    return availableDepartures;
  }

  // populate allocation cutoff and booking window if doesn't exist with default values
  if (!$scope.lineEditService.line.allocationCutoff) {
    $scope.lineEditService.line.allocationCutoff = $scope.lineEditService.line.bookBefore;
  }
  if (!$scope.lineEditService.line.allocationBookingWindow) {
    $scope.lineEditService.line.allocationBookingWindow = $scope.lineEditService.line.reverseCutoff;
  }

  function filterEventsByDepartureDay(events, departureDay) {
    return events.filter(function (event) {
      if (event.dow && event.dow.length) {
        return event.dow.includes(parseInt(moment(departureDay).format('e'), 10));
      } else {
        return moment.tz(event.start, event.currentTimezone).format('YYYY-MM-DD') === departureDay;
      }
    });
  }

  function filterEventsByDeparture(events, departureTime, departure) {
    return events.filter(function (event) {
      if (event.dow && event.dow.length > 0) {
        var eventStartDuration = moment.duration(event.start);
        var eventStart = pad(eventStartDuration.get('hours'), 2, 0) + ':' + pad(eventStartDuration.get('minutes'), 2, 0) + ':00';
        return eventStart === departureTime;
      } else {
        var eventStartFormat = moment.tz(event.start, event.currentTimezone).utc().format('YYYY-MM-DD HH:mm:ss');
        var departureFormat = moment.tz(departure, event.currentTimezone).utc().format('YYYY-MM-DD HH:mm:ss');
        return eventStartFormat === departureFormat;
      }
    });
  }

  function csvToJson(data) {
    var results = [];
    data = data.trim();
    var lines = data.split(/\r?\n/);

    var fields = [];
    if (lines[0]) {
      fields = lines[0].split(',');
    }
    for (var i = 1; i < lines.length; i++) {
      var line = lines[i];
      var lineDataSplit = line.split(',');
      var resultObj = {};
      for (var j = 0; j < fields.length; j++) {
        resultObj[fields[j].trim()] = (lineDataSplit[j] || '').trim();
      }
      results.push(resultObj);
    }
    return results;
  }

  function checkCSVEventsDates(departureDate, departureTime, direction, lineNum) {
    const errorMessages = [];
    var checkEvents = true;
    if (!departureDate) {
      errorMessages.push('line ' + lineNum + ': missing departure date');
      checkEvents = false;
    } else if (!moment(departureDate, "YYYY-MM-DD").isValid()) {
      errorMessages.push('line ' + lineNum + ': invalid date format (expected yyyy-mm-dd)');
      checkEvents = false;
    } else {
      departureDate = moment(departureDate).format('YYYY-MM-DD');
    }

    if (!departureTime) {
      errorMessages.push('line ' + lineNum + ': missing departure time');
      checkEvents = false;
    } else if (!moment(departureTime, "HH:mm:ss", true).isValid()) {
      errorMessages.push('line ' + lineNum + ': invalid time format (expected hh:mm:ss)');
      checkEvents = false;
    }

    if (checkEvents) {
      var directionName = direction === 'a to b' ? 'aToB' : 'bToA';
      var departure = departureDate + 'T' + departureTime;
      var events = lineEditService.line.schedule[directionName + 'Events'];

      events = filterEventsByDeparture(events, departureTime, departure);

      if (events.length === 0) {
        errorMessages.push('line ' + lineNum + ': trip not exist at this departure');
      }
    }
    return errorMessages;
  }

  function validateFullyBookedStructureFromCSV(jsonArray) {
    var errorMessages = [];
    jsonArray.forEach(function (record, index) {
      var lineNum = parseInt(index + 1, 10);
      if (!['block', 'unblock', 'block-day', 'unblock-day'].includes(record.action)) {
        errorMessages.push('Line: ' + lineNum + ', Action: ' + record.action + ', is not supported');
      }

      if (!record.direction) {
        errorMessages.push('Line ' + lineNum + ': Missing direction');
      } else if (record.direction !== 'a to b' && record.direction !== 'b to a') {
        errorMessages.push('Line ' + lineNum + ': Invalid direction  (expected "a to b" or "b to a")');
      }

      if (['block', 'unblock'].includes(record.action)) {
        errorMessages = errorMessages.concat(checkCSVEventsDates(record.departureDate, record.departureTime, record.direction, lineNum));

      }

      if (['block-day', 'unblock-day'].includes(record.action)) {
        if (!record.departureDate) {
          errorMessages.push('Line' + lineNum + ': Departure date is missing');
        } else if (!record.departureDate.match(new RegExp('([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))'))) {
          errorMessages.push('Line' + lineNum + ': Invalid date: ' + record.departureDate + ', expected format: "YYYY-MM-DD"');
        } else if (!moment(record.departureDate).isValid()) {
          errorMessages.push('Line' + lineNum + ': Date: ' + record.departureDate + ', does not exists');
        }
      }

    });

    if (errorMessages.length > 0) {

      $uibModal.open({
        templateUrl: 'views/error-messages.html',
        controller: 'ErrorMessagesCtrl',
        resolve: {
          errorMessages: function () {
            return errorMessages;
          }
        }
      });

      return false;
    }

    return true;
  }

  function validateAllocationsFromCSV(data) {
    var errorMessages = [];
    data = data.trim();
    var lines = data.split(/\r?\n/);

    for (var i = 1; i < lines.length; i++) {
      var lineNum = i + 1;
      var line = lines[i];
      var tripInputs = line.split(',');
      var checkEvents = true;
      var direction = !tripInputs[0] ? '' : tripInputs[0].trim();
      var departureDate = !tripInputs[1] ? '' : tripInputs[1].trim();
      var departureTime = !tripInputs[2] ? '' : tripInputs[2].trim();
      var allocatedSeats = !tripInputs[3] ? '' : tripInputs[3].trim();

      if (!direction && !departureDate && !departureTime && !allocatedSeats) {
        continue;
      }

      if (!direction) {
        errorMessages.push('line ' + lineNum + ': missing direction');
        checkEvents = false;
      } else if (direction !== 'a to b' && direction !== 'b to a') {
        errorMessages.push('line ' + lineNum + ': invalid direction  (expected "a to b" or "b to a")');
        checkEvents = false;
      }

      if (!allocatedSeats) {
        errorMessages.push('line ' + lineNum + ': missing allocated seats');
      } else if (isNaN(allocatedSeats)) {
        errorMessages.push('line ' + lineNum + ': invalid allocated seats (expected number)');
      }

      errorMessages = errorMessages.concat(checkCSVEventsDates(departureDate, departureTime, direction, lineNum));
    }

    if (errorMessages.length > 0) {

      $uibModal.open({
        templateUrl: 'views/error-messages.html',
        controller: 'ErrorMessagesCtrl',
        resolve: {
          errorMessages: function () {
            return errorMessages;
          }
        }
      });

      return false;
    }

    return true;
  }

  function validatePickupDropOffFromCSV(data, type) {
    var errorMessages = [];
    var lines = data.split(/\r?\n/);

    for (var i = 1; i < lines.length; i++) {
      var lineNum = i + 1;
      var line = lines[i];
      var pickupInputs = line.split(',');
      var hotelName = !pickupInputs[0] ? '' : pickupInputs[0].trim();
      var time = !pickupInputs[1] ? '' : pickupInputs[1].trim();

      if (!hotelName && !time) {
        continue;
      }

      if (!hotelName) {
        errorMessages.push('line ' + lineNum + ': missing hotel name');
      }

      if (type === 'dropOff') {
        // do nothing, hotel dropOff doesn't need validation of time
      } else if (!time) {
        errorMessages.push('line ' + lineNum + ': missing time');
      } else if (!Number.isInteger(Number(time))) {
        errorMessages.push('line ' + lineNum + ': time expected to be an integer, got value "' + time + '" instead.');
      } else {
        time = Number(time);
      }
    }

    if (errorMessages.length > 0) {
      $uibModal.open({
        templateUrl: 'views/error-messages.html',
        controller: 'ErrorMessagesCtrl',
        resolve: {
          errorMessages: function () {
            return errorMessages;
          }
        }
      });

      return false;
    }

    return true;
  }


  function generatePickupDropOffsFromCSV(extra, data, type) {
    data = data.trim();
    var lines = data.split(/\r?\n/);

    extra.data = extra.data.filter(function (item) {
      return item.name;
    });

    for (var i = 1; i < lines.length; i++) {
      var line = lines[i];
      var tripInputs = line.split(',');
      var hotelName = tripInputs[0].trim();
      var time = tripInputs[1] && type === 'pickup' ? Number(tripInputs[1]) : 0;
      var beforeAfter = time <= 0 ? 'before' : 'after';

      var extraDataItem = {
        name: hotelName,
        time: Math.abs(time),
        beforeAfter: beforeAfter,
      };

      extra.data.push(extraDataItem);
    }
  }

  $scope.pickupDropOffCSVExplanation = function (extra) {
    var explain = 'CSV format: hotel name (string)';
    if (lineEditService.isPickupExtra(extra)) {
      explain += ', time in minutes (integer: negative = before departure, positive = after departure)';
    }
    return explain;
  };

  function generateAllocationsFromCSV(data) {
    data = data.trim();
    var lines = data.split(/\r?\n/);

    for (var i = 1; i < lines.length; i++) {
      var line = lines[i];
      var tripInputs = line.split(',');
      var direction = tripInputs[0].trim() === 'a to b' ? 'aToB' : 'bToA';
      var departureDate = moment(tripInputs[1].trim()).format('YYYY-MM-DD');
      var departureTime = tripInputs[2].trim();
      var allocatedSeats = parseInt(tripInputs[3].trim());
      var departure = departureDate + 'T' + departureTime;
      var events = lineEditService.line.schedule[direction + 'Events'];

      events = filterEventsByDeparture(events, departureTime, departure);

      events.forEach(function (event) {
        var trip = {
          eventId: event._id,
          direction: direction,
          allocatedSeats: allocatedSeats,
        };

        if (event.dow && event.dow.length > 0) {
          trip.departure = {date: moment.tz(departure, event.currentTimezone).utc(), timezone: event.currentTimezone};

          var tripDuration = moment.duration(event.end).subtract(moment.duration(event.start));
          trip.arrival = {
            date: moment.tz(departure, event.currentTimezone).utc().add(tripDuration.get('hours'), 'hours').add(tripDuration.get('minutes'), 'minutes'),
            timezone: event.currentTimezone
          };
        } else {
          trip.departure = {date: event.start, timezone: event.currentTimezone};
          trip.arrival = {date: event.end, timezone: event.currentTimezone};
        }

        lineEditService.line.updatedTrips.push(trip);
      });
    }
  }

  $scope.uploadPickupDropOffsWithCSV = function (extra) {
    var type = $scope.lineEditService.isPickupExtra(extra) ? 'pickup' : 'dropOff';

    return function (event) {
      var fileSelected = event.target.files[0];
      var fileExt = fileSelected.name.split('.')[1];

      if (fileExt !== 'csv') {
        return $rootScope.$emit('notify', {type: 'error', title: '', message: 'File is not supported.'});
      }

      var fileReader = new FileReader();

      fileReader.onloadend = function (e) {
        var data = e.target.result;
        var isValid = validatePickupDropOffFromCSV(data, type);
        if (isValid) {
          generatePickupDropOffsFromCSV(extra, data, type);
          $rootScope.$emit('notify', {type: 'success', title: '', message: 'Uploaded successfully'});
        }
        event.target.value = '';
      };

      fileReader.readAsBinaryString(fileSelected);
    };
  };

  $scope.uploadAllocationsWithCSV = function (event) {
    var fileSelected = event.target.files[0];
    var fileExt = fileSelected.name.split('.')[1];

    if (fileExt !== 'csv') {
      return $rootScope.$emit('notify', {type: 'error', title: '', message: 'File is not supported.'});
    }

    var fileReader = new FileReader();

    fileReader.onloadend = function (e) {
      var data = e.target.result;
      var isValid = validateAllocationsFromCSV(data);
      if (isValid) {
        generateAllocationsFromCSV(data);
        $rootScope.$emit('notify', {type: 'success', title: '', message: 'Uploaded successfully'});
      }
      event.target.value = '';
    };

    fileReader.readAsBinaryString(fileSelected);
  };

  $scope.removeAllEvents = function () {
    $uibModal.open({
      templateUrl: 'views/lines/remove-all-trips.html',
      controller: 'RemoveAllTripsCtrl',
      resolve: {
        lineEditService: [function () {
          return lineEditService;
        }]
      }
    });
  };

  function removeFullyBookedDayFromEvent(event, day) {
    var index = event.full.indexOf(day);
    if (index > -1) {
      event.full.splice(index, 1);
    }
  }

  function addFullyBookedDayToEvent(event, day) {
    var found = event.full.find(function (date) {
      return date === day;
    });
    if (!found) {
      event.full.push(day);
    }
  }

  function modifyEventsFullyBookedFromCSV(jsonArray) {
    jsonArray.forEach(function (record) {
      var direction = record.direction === 'a to b' ? 'aToB' : 'bToA';
      var events = lineEditService.line.schedule[direction + 'Events'];
      var departure = record.departureDate + 'T' + record.departureTime;

      if (['block', 'unblock'].includes(record.action)) {
        events = filterEventsByDeparture(events, record.departureTime, departure);
        events.forEach(function (event) {
          if (record.action === 'unblock') {
            removeFullyBookedDayFromEvent(event, record.departureDate);
          } else if (record.action === 'block') {
            addFullyBookedDayToEvent(event, record.departureDate);
          }
        });
      }

      if (['block-day', 'unblock-day'].includes(record.action)) {
        events = filterEventsByDepartureDay(events, record.departureDate);
        events.forEach(function (event) {
          if (record.action === 'unblock-day') {
            removeFullyBookedDayFromEvent(event, record.departureDate);
          } else if (record.action === 'block-day') {
            addFullyBookedDayToEvent(event, record.departureDate);
          }
        });
      }

    });
  }

  $scope.updateFullyBookedFromCSV = function (event) {
    var fileSelected = event.target.files[0];
    var fileExt = fileSelected.name.split('.')[1];

    if (fileExt !== 'csv') {
      return $rootScope.$emit('notify', {type: 'error', title: '', message: 'File is not supported.'});
    }

    var fileReader = new FileReader();

    fileReader.onloadend = function (e) {
      var data = e.target.result;
      var json = csvToJson(data);
      var isValid = validateFullyBookedStructureFromCSV(json);
      if (isValid) {
        modifyEventsFullyBookedFromCSV(json);
        $rootScope.$emit('notify', {type: 'success', title: '', message: 'Uploaded successfully'});
      }
      event.target.value = '';
      $('#inbound-schedule').fullCalendar('refetchEvents');
      $('#outbound-schedule').fullCalendar('refetchEvents');
    };

    fileReader.readAsBinaryString(fileSelected);

  };

  function handleError(err) {
    var message = '';
    if (_.isArray(err)) {
      message = err.join('<br>');
    } else {
      message = _.get(err, 'data.message') || err.message || err || '';
    }
    console.error('failed operation with error: \n' + message.replace(/<br>/g, '\n'));
    if(message !== 'Closed Modal') {
      $rootScope.$emit('notify', {type: 'error', title: 'Error', message});
    }
  }

  function getTransferMappingObj(transferMappings, transferId) {
    const transfer = transferMappings.find(transfer => transfer.transferId === transferId);
    const supplierMapping = _.get(transfer, 'supplierMapping', []);
    return supplierMapping.map(supplierMapping => {
      return {
        transferId,
        lineId: transfer.lineId,
        supplierMapping,
        disableSync: transfer.disableSync
      };
    });
  }

  $scope.$watch('lineEditService.line.transferMappings', function(transferMappings) {
    if (transferMappings.length === 0) {
      lineEditService.line.isSupplierApi = false;
      $scope.aToBExistingMappings = [];
      $scope.bToAExistingMappings = [];
    } else {
        if (!lineEditService.line.isScrapingAvailability) {
          lineEditService.line.isSupplierApi = true;
        }
        $scope.aToBExistingMappings = getTransferMappingObj(transferMappings, lineEditService.line.aToBTransferId);
        $scope.bToAExistingMappings = getTransferMappingObj(transferMappings, lineEditService.line.bToATransferId);
    }
  })

  $scope.setTransferMapping = function(transfer) {
    const selectedApiSupplier = _.get(lineEditService, 'selectedApiSupplier.name');
    const supplierApiName = selectedApiSupplier || lineEditService.line.supplierName;

    transferMappingEditService.setTransferMapping(lineEditService.line, transfer, supplierApiName)
    .then(function() {
      $timeout(function() {
        transfer.isCurrentLine = true;
        transfer.existedMappings.push({ lineId: lineEditService.line._id });
      }, 0);
    })
    .then(function() {
      return transferMappingEditService.getTransferMappings([lineEditService.line.aToBTransferId, lineEditService.line.bToATransferId])
      .then(function(values) {
        lineEditService.line.transferMappings = values;
      })
    })
    .then(function() {
      const selectedApiSupplierId = _.get(lineEditService, 'selectedApiSupplier.supplierId');
      if (selectedApiSupplierId && selectedApiSupplierId !== lineEditService.line.supplier._id) {
        companyEditService.getCompany(selectedApiSupplierId).then(function(company) {
          lineEditService.line.supplier = company;
        })
      }
    })
    .catch(handleError);
  }

  $scope.deleteTransferMapping = function(transfer) {
    transferMappingEditService.deleteTransferMapping(transfer).then(function(response) {
      if(response) {
      return transferMappingEditService.getTransferMappings([lineEditService.line.aToBTransferId, lineEditService.line.bToATransferId])
      .then(function(transferMappings) {
          lineEditService.line.transferMappings = transferMappings;
          if (response && ($scope.supplierAToBTransfers || $scope.supplierBToATransfers)) {
            $scope.performTransferSearch(lineEditService.selectedDate, lineEditService.daysInAdvance);
          }
        })
      }
    })
    .catch(handleError);
  }

  function adjustTransferProperties(transfers, supplierCompanyIds, fromCityId, toCityId) {
    return transfers.reduce(function(acc, transfer) {
      if(supplierCompanyIds.includes(transfer.company.id)) {
        transfer.fromCityId = fromCityId;
        transfer.toCityId = toCityId;
        transfer.isCurrentLine = transfer.existedMappings.some(mapping => mapping.lineId === lineEditService.line._id);
        acc.push(transfer);
      }
      return acc;
    }, []);
  }

  lineEditService.showTransferSearchResults = false;
  $scope.performTransferSearch = function (selectedDateInDateFormat, daysInAdvance) {
    $scope.supplierAToBTransfers = [];
    $scope.supplierBToATransfers = [];
    const fromCityId = _.get(lineEditService.line, 'stationA.city.id');
    const toCityId = _.get(lineEditService.line, 'stationB.city.id');
    const selectedApiSupplier = _.get(lineEditService, 'selectedApiSupplier.name');
    const supplierApiName = selectedApiSupplier || lineEditService.line.supplierName;

    supplierApiService.getCompanyMappings(supplierApiName)
    .then(function(companiesMapping) {
      const company = companiesMapping.find(companyMapping => companyMapping.bookawayData.companyId === lineEditService.line.company._id);
      if (!company) {
        return Promise.reject('Company ' + lineEditService.line.company._id + ' is not mapped');
      }
      const supplierCompanyIds = company ? company.supplierData.map(item => item.companyId): [];

      Promise.all([transferMappingEditService.performTransferSearch(selectedDateInDateFormat, daysInAdvance, fromCityId, toCityId, supplierApiName),
        transferMappingEditService.performTransferSearch(selectedDateInDateFormat, daysInAdvance, toCityId, fromCityId, supplierApiName)])
        .then(function (values) {
          lineEditService.showTransferSearchResults = true;
          $scope.supplierAToBTransfers = adjustTransferProperties(values[0], supplierCompanyIds, fromCityId, toCityId);
          $scope.supplierBToATransfers = adjustTransferProperties(values[1], supplierCompanyIds, toCityId, fromCityId);
        })
    })
    .catch(handleError);
  }

  if (!lineEditService.isSupplierApi || !lineEditService.transferMappings.length) {
      $scope.supplierList = supplierApiService.supplierList;
  }
}

'use strict';

/*
exported
stationLineSelectorDirective
 */

function stationLineSelectorDirective() {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/lines/station-line-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      cityLabel: '@cityLabel',
      stationLabel: '@stationLabel',
      hideMap:'@hideMap',
      hideEditStation: '@hideEditStation'
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      ngModelCtrl.$render = function() {
        scope.station = ngModelCtrl.$viewValue;
      };

      ngModelCtrl.$validators.required = function(modelValue) {
        return !!(modelValue && modelValue._id);
      };

      scope.$watch('station', function(station) {
        if (station && ((scope.city && scope.city._id !== station.city._id) || !scope.city)) {
          scope.city = station.city;
        }

        if (station && station.loc) {
          scope.coordinates = [station.loc[1], station.loc[0]];
        }

        if (station) {
          ngModelCtrl.$setViewValue(station);
        }
      });
    }
  };
}

'use strict';

/*
exported
keyDetailsLineFormDirective
 */

function keyDetailsLineFormDirective(Restangular, SearchRestangular, RideRulesService, $q) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/lines/key-details-line-form.directive.html',
    scope: {
      ngModel: '=ngModel',
      source: '@',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      SearchRestangular.one('amenities', 'en').get().then((res) => {
        scope.amenityCommentsOptions = res
      })

      scope.rideRulesService = RideRulesService;
      scope.comments = {};
      scope.featureValues = {};
      scope.freeTextInput = {};
      function createView() {
        var keyDetails = ngModelCtrl.$viewValue.keyDetails || [];
        keyDetails.forEach(keyDetail=>{
          scope.comments[keyDetail.key._id] = keyDetail.comment;
        });

        scope.keyDetails = scope.keyDetailDefinitions.map(function(keyDetailDefinition) {
          var keyDetail = {
            key: keyDetailDefinition
          };

          if (keyDetails.some(function(val) {
              return val.key._id === keyDetailDefinition._id;
            })) {
            keyDetail.selected = true;
          }

          return keyDetail;

        });
        var features = ngModelCtrl.$viewValue.features || [];
        features.forEach(feature=>{
          scope.featureValues[feature.slug] = feature.value;
          if (feature.comments && feature.comments.length > 0) {
            scope.freeTextInput[feature.slug] = feature.comments[0];
          }
        });

        scope.features = scope.featuresDefinitions.map(function(featureDefinition){
          const feature = {
            key: featureDefinition,
          };
        
          const isFeatureSelected = features.some(val => val.slug === featureDefinition.slug);
          if (isFeatureSelected) {
            feature.selected = true;
          }
        
          return feature;
        });
      }

      scope.onCommentChange = function(keyDetail) {
        const currentKeyId = keyDetail.key._id
        const currentInputValue = scope.comments[currentKeyId];
        const keyDetails = ngModelCtrl.$viewValue.keyDetails;
        const currentKeyDetail = keyDetails.find(keyDetail=>keyDetail.key._id===currentKeyId || keyDetail.key===currentKeyId)
        currentKeyDetail.comment = currentInputValue
        ngModelCtrl.$setViewValue({keyDetails: keyDetails});
      }

      scope.onFeatureValueChange = function (feature) {
        var features = ngModelCtrl.$viewValue.features || [];
      
        var item = features.find(item => item.slug === feature.key.slug);
        if (item) {
          item.value = scope.featureValues[item.slug];
        }
      };

      scope.showFreeText = function(feature, selectedOptionSlug) {
        if (feature && feature.key.values && selectedOptionSlug) {
          const selectedOption = feature.key.values.find(option => option.slug === selectedOptionSlug);
          return feature.selected && selectedOption && selectedOption.parameters && selectedOption.parameters.length > 0;
        }
        return false;
      };
      

      scope.getParameterName = function (values, option) {
        if(values && option){
          return values.find((value) => value.slug === option).parameters[0].name;
        }
        return '';
      };

      scope.onFreeTextInputChange = function (feature) {
        var features = ngModelCtrl.$viewValue.features || [];
      
        var item = features.find(item => item.slug === feature.key.slug);
        if (item) {
          item.comments[0] = scope.freeTextInput[feature.key.slug];
        }
      
        ngModelCtrl.$setViewValue(Object.assign({}, ngModelCtrl.$viewValue, { features: features }));
      };
      
      
      scope.updateKeyDetail = function (keyDetail) {
        var keyDetails = ngModelCtrl.$viewValue.keyDetails || [];

        if (keyDetail.selected) {
          keyDetails.push({ key: keyDetail.key._id });
        } else {
          var index = keyDetails.findIndex(item => item.key._id === keyDetail.key._id);
          if (index !== -1) {
            keyDetails.splice(index, 1);
          }
        }
      
        ngModelCtrl.$setViewValue(Object.assign({}, ngModelCtrl.$viewValue, { keyDetails: keyDetails }));    
      };

      scope.updateFeature = function (feature) {
        if (feature.selected && feature.key.values.length > 0 && !feature.value) {
          scope.featureValues[feature.key.slug] = feature.key.values[0].slug;
          feature.value = feature.key.values[0];
        }
        var features = ngModelCtrl.$viewValue.features || [];
      
        if (feature.selected) {
          features.push({
            slug: feature.key.slug,
            name: feature.key.name,
            value: feature.value.slug,
          });
        } else {
          var index = features.findIndex(item => item.slug === feature.key.slug);
          if (index !== -1) {
            features.splice(index, 1);
          }
        }
      
        ngModelCtrl.$setViewValue(Object.assign({}, ngModelCtrl.$viewValue, { features: features }));
      };
      

      ngModelCtrl.$formatters.push(function(modelValue) {
        return {
          keyDetails: modelValue && modelValue.keyDetails || [],
          features: modelValue && modelValue.features || [],
        };
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        ngModelCtrl.$modelValue.keyDetails = viewValue && viewValue.keyDetails || [];
        ngModelCtrl.$modelValue.features = viewValue && viewValue.features || [];
        return ngModelCtrl.$modelValue;
      });

      ngModelCtrl.$render = function() {

       if (scope.keyDetailDefinitions && scope.featuresDefinitions) {
         if (scope.source === scope.rideRulesService.ATTRIBUTE_EDIT_SOURCES.RULE) getKeyDetailsObjects(ngModelCtrl, scope);
         createView();
       } else {
          var promises = [];

          if (!scope.keyDetailDefinitions) {
            const promise1 = Restangular.all('keydetaildefinitions').getList({ limit: 0 })
              .then(function(keyDetailDefinitions) {
                scope.keyDetailDefinitions = keyDetailDefinitions;
              });
            promises.push(promise1);
          }

          if (!scope.featuresDefinitions) {
            var promise2 = Restangular.all('keydetails').getList({ limit: 0 })
              .then(function(features) {
                scope.featuresDefinitions = features;
              });

            promises.push(promise2);
          }

          $q.all(promises).then(function() {
            if (scope.source === scope.rideRulesService.ATTRIBUTE_EDIT_SOURCES.RULE) getKeyDetailsObjects(ngModelCtrl, scope);
            createView();
          });
        }
      };
    }
  };

  function getKeyDetailsObjects(ngModelCtrl, scope) {
    if (ngModelCtrl.$viewValue && Array.isArray(ngModelCtrl.$viewValue.keyDetails) && scope.keyDetailDefinitions) {
        var mappedKeyDetails = [];

        ngModelCtrl.$viewValue.keyDetails.forEach(function (keyDetailItem) {
          var detailObj = scope.keyDetailDefinitions.find(function (definition) {
            return definition._id === keyDetailItem._id;
          });

          if (detailObj) {
            mappedKeyDetails.push({ key: detailObj, comment: keyDetailItem.comment});
          }
        });

        ngModelCtrl.$viewValue.keyDetails = mappedKeyDetails;
    }
  }
}

'use strict';

/*
exported
extraInfosLineFormDirective
 */

function extraInfosLineFormDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/lines/extra-info-line-form.directive.html',
    scope: {
      ngModel: '=ngModel'
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {

      function createView() {
        var selectedPassengerExtraInfoDefinitions = ngModelCtrl.$viewValue.passengerExtraInfoDefinitions || [];

        scope.formattedPassengerExtraInfoDefinitions = scope.passengerExtraInfoDefinitions.filter((extra) => extra.slug !== 'outgoing_flight_number' && extra.slug !== 'incoming_flight_number').map(function(passengerExtraInfoDefinition) {
          var formattedPassengerExtraInfoDefinition = {
            type: passengerExtraInfoDefinition
          };

          if (selectedPassengerExtraInfoDefinitions.some(function(selectedPassengerExtraInfoDefinition) {
              return selectedPassengerExtraInfoDefinition._id === passengerExtraInfoDefinition._id;
            })) {
            formattedPassengerExtraInfoDefinition.selected = true;
          }

          return formattedPassengerExtraInfoDefinition;
        });
      }

      scope.updatePassengerExtraInfoDefinition = function(formattedPassengerExtraInfoDefinition) {
        var selectedPassengerExtraInfoDefinitions = ngModelCtrl.$viewValue.passengerExtraInfoDefinitions;

        if (formattedPassengerExtraInfoDefinition.selected) {
          selectedPassengerExtraInfoDefinitions.push(formattedPassengerExtraInfoDefinition.type.plain());
        } else {
          selectedPassengerExtraInfoDefinitions = selectedPassengerExtraInfoDefinitions.filter(function(selectedPassengerExtraInfoDefinition) {
            return selectedPassengerExtraInfoDefinition._id !== formattedPassengerExtraInfoDefinition.type._id;
          });
        }

        ngModelCtrl.$setViewValue({passengerExtraInfoDefinitions: selectedPassengerExtraInfoDefinitions});
      };

      ngModelCtrl.$formatters.push(function(modelValue) {
        return {passengerExtraInfoDefinitions: modelValue};
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.passengerExtraInfoDefinitions;
      });



      ngModelCtrl.$render = function() {
        if (scope.passengerExtraInfoDefinitions) {
          createView();
        } else {
          Restangular
            .all('passengerextrainfodefinitions')
            .getList({limit: 0})
            .then(function(passengerExtraInfoDefinitions) {
              scope.passengerExtraInfoDefinitions = passengerExtraInfoDefinitions.filter((extra) => extra.isShowOnAdmin !== false);
              createView();
            });
        }
      };
    }
  };
}

'use strict';

/* exported
 lineEditProvider
 */

function lineEditProvider() {
  /* jshint validthis: true */
  this.line = {};
  this.originalSupplier = {};
  this.originalCompany = {}
  this.form = {};
  this.activeTab = {};

  function createPrice(supplierCurrency = 'USD') {
    return {
      amount: 0,
      cost: 0,
      currency: 'USD',
      returnDiscount: 10,
      returnCostDiscount: 0,
      amountInSupplierCurrency: 0,
      costInSupplierCurrency: 0,
      supplierCurrency: supplierCurrency,
    };
  }

  function hasCrossDayEvent(events = []) {
    return events.some(event => event.dow && event.dow.length && typeof event.end !== 'string' && event.end.asHours() >= 24);
  }

  /* jshint validthis: true */
  this.$get = ['InventoryRestangular', '$rootScope', '$state', 'appConfig', 'DataCollector', 'moment', 'uuid', '$uibModal', 'Restangular', function(InventoryRestangular, $rootScope, $state, appConfig, DataCollector, moment, uuid, $uibModal, Restangular) {
    let lineEditService = {};
    lineEditService.saveLock = false;
    let baseLines = InventoryRestangular.all('lines');
    lineEditService.newBlockEvents = [];

    let passengerExtraInfoDefinitions = [];
    if (window.user && window.user.shouldSeeFullAdmin) {
      Restangular.all('passengerextrainfodefinitions').getList().then(function (response) {
        passengerExtraInfoDefinitions = response;
      });
    }

    const luggageToggle={
      VEHICLE:'vehicle',
      PASSENGER:'passenger'
    }
    const bagTypesInitial = {
      largeBag:{amount:1,measureUnit:'cm'},
      handbag:{amount:1,measureUnit:'cm'},
      skiAndSnowboard:{amount:1},
      passengerOrVehicle:luggageToggle.PASSENGER
    }
    lineEditService.initLuggageData = function() {
      const luggageInitKeys = Object.keys(bagTypesInitial);
      luggageInitKeys.forEach((key)=>{
        if(!this.line.luggage.customLuggage[key]){
          this.line.luggage.customLuggage[key] = bagTypesInitial[key]
        }
      })
    };
    function setLuggagePolicyType(line) {
      if(Object.keys(line.luggage.customLuggage).length>1){
       return line.luggagePolicyType ='customLuggage'
      }
      if(line.luggage.openText || !line.luggagePolicyType){
       return line.luggagePolicyType ='openText'
      }

    }

    function formatLuggage(line) {
      if(!line.luggage){
        line.luggage={}
      }
      if(!line.luggage.customLuggage){
        line.luggage.customLuggage = {};
      }
      line.luggage.customLuggage.selectedLuggages={}
      setLuggagePolicyType(line);
      if(Object.keys(line.luggage.customLuggage).length>1){
        const customLuggagesFields = Object.keys(line.luggage.customLuggage)
        customLuggagesFields.forEach((field)=>{
            const luggageTypeValue =line.luggage.customLuggage[field]
          if(!luggageTypeValue){
            return;
          }
          if(luggageTypeValue.hasOwnProperty('amount') && !_.get(luggageTypeValue,'amount')){
            return;
          }
          line.luggage.customLuggage.selectedLuggages[`has_${field}`] = true;
        })
      }
      lineEditService.initLuggageData();
    }

    function saveLuggage(line) {
      const lineCopy = Object.assign({},line)
      if(lineCopy.luggagePolicyType === 'openText'){
         lineCopy.luggage.customLuggage = null
      }
      if(lineCopy.luggagePolicyType === 'customLuggage'){
        // lineCopy.luggage.openText = null;
        const customLuggagesFields = Object.keys(line.luggage.customLuggage)
        customLuggagesFields.forEach((field)=>{
          //passengeOrVehicle is a field that always needs to be saved if we choose custom luggage
           if(field === 'passengerOrVehicle' || field ==='selectedLuggages'){
            return
           }
           if(!line.luggage.customLuggage.selectedLuggages[`has_${field}`]){
               lineCopy.luggage.customLuggage[field] = null;
           }
        })
      }
      if(!lineCopy.luggagePolicyType){
        lineCopy.luggage = {}
      }
      return lineCopy.luggage
    }

    function saveAdditionalServices(line) {
      if (line.additionalServices && line.additionalServices.customServices && line.additionalServices.customServices.length) {
        const additionalServices = _.cloneDeep(line.additionalServices);
          // filter empty custom services
        additionalServices.customServices = additionalServices.customServices.filter(customService => customService.serviceType || customService.serviceContent);
        return additionalServices;
      }
      return line.additionalServices;
    }

    function formatPublishedAt(line) {
      if (line.publishedAt){
        line.publishedAt = new Date(line.publishedAt);
      }
    }

    lineEditService.getStationNameById = function(stationId) {
      const station = lineEditService.line.aToBMultiStations.find(function(station) {
        return station.stationId._id === stationId;
      });
      return station ? station.stationId.name : null;
    }
    lineEditService.togglePassengerOrVehicle = function() {
      const luggage = this.line.luggage.customLuggage;
      const passengerOrVehicle = luggage.passengerOrVehicle;

      luggage.passengerOrVehicle = (passengerOrVehicle === luggageToggle.VEHICLE) ? luggageToggle.PASSENGER : luggageToggle.VEHICLE;
    }

    lineEditService.getStationById = function (stationId) {
      return lineEditService.line.aToBMultiStations.find(function(station) {
        return station.stationId._id === stationId;
      });
    }

    function getTime(duration) {
      var momentDuration = moment.duration(duration);

      return pad(momentDuration.hours(), 2, '0') + ':' + pad(momentDuration.minutes(), 2, '0');
    };

    function pad(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    lineEditService.getEventsDepartureTimesOrIntervals = function () {
      const allEvents = lineEditService.line.schedule.aToBEvents.concat(
        lineEditService.line.schedule.bToAEvents
      );

      const isPrivateTransfer = lineEditService.line.privateTransfer.isActivated;
      const availableIntervals = [];
      const availableDepartureTimes = {};

      allEvents.forEach((event) => {
        const startTime = getTime(event.start);
        const endTime = getTime(event.end);
        if (isPrivateTransfer) {
          const intervalExists = availableIntervals.find(
            (interval) => interval.start === startTime && interval.end === endTime
          );
          if (!intervalExists) {
            availableIntervals.push({
              start: startTime,
              end: endTime,
              label: `${startTime}-${endTime}`
            })
          }
        } else {
          if (event.type !== "single") {
            availableDepartureTimes[startTime] = startTime;
          }
        }
      });

      if (isPrivateTransfer) {
        lineEditService.availableIntervals = availableIntervals;
      } else {
        lineEditService.availableDepartureTimes = availableDepartureTimes;
      }
    };

    lineEditService.refreshPriceRules = function () {
      lineEditService.line.priceExceptions = (
        lineEditService.line.priceExceptions || []
      ).reduce((acc, rule) => {
        let start;

        if (rule.start) {
          start = moment.utc(rule.start);
          const ruleTime = start.format('HH:mm');
          if (ruleTime !== "00:00") {
            start = moment.utc(rule.start).add('day', 1);
          }
        }

        const end = rule.end && moment.utc(rule.end);

        let departureTime = [];
        if (rule.departureTime) {
          departureTime = rule.departureTime;

          if (!Array.isArray(rule.departureTime) && rule.departureTime !== "") {
            departureTime = [rule.departureTime];
          } else if (
            rule.departureTime === "" ||
            (rule.departureTime[0] && rule.departureTime[0] === "")
          ) {
            departureTime = [];
          }
        }
        const updatedRule = Object.assign(rule, {
          start: start ? start.startOf("day").format("YYYY-MM-DD") : '',
          end: end ? end.endOf("day").format("YYYY-MM-DD") : '',
          startDisplay: start && start.format("DD/MM/YYYY"),
          endDisplay: end && end.format("DD/MM/YYYY"),
          departureTime,
          fromStations: (rule.fromStations || [])
            .map((st) => {
              const station = lineEditService.getStationById(st.id);
              if (station) {
                return {
                  id: station.stationId._id,
                  name: station.stationId.name,
                };
              }
            })
            .filter((station) => !!station),
          toStations: (rule.toStations || [])
            .map((st) => {
              const station = lineEditService.getStationById(st.id);
              if (station) {
                return {
                  id: station.stationId._id,
                  name: station.stationId.name,
                };
              }
            })
            .filter((station) => !!station),
          timeIntervals: (rule.timeIntervals || []).map((interval) =>
            Object.assign(interval, {
              label: `${interval.start}-${interval.end}`,
            })
          ),
          passengerTypes: (rule.passengerTypes || [])
            .map((pt) =>
              lineEditService.line.company.passengerTypes.find(
                (type) => type.id === pt.id
              )
            )
            .filter((pt) => !!pt),
        });
        acc.push(updatedRule);
        return acc;
      }, []);
    };

    function checkEquality(param1 = [], param2 =[]) {
      let isSame = param1.length === 0 && param2.length === 0;
      for (let i = 0; i < param1.length; i++) {
        const index = param2.findIndex(el => el.id === param1[i].id);
        if (index > -1) {
          isSame = true;
          break;
        }
      }
      return isSame;
    }

    lineEditService.isOverlappingRules = function (ruleA, ruleB) {
      let isOverlapping = false;
      const ruleAStart = ruleA.start ? moment(ruleA.start) : null;
      const ruleAEnd = ruleA.end ? moment(ruleA.end) : null;
      const ruleBStart = ruleB.start ? moment(ruleB.start) : null;
      const ruleBEnd = ruleB.end ? moment(ruleB.end) : null;

      if (
        ruleBStart &&
        ruleBStart.isBetween(ruleAStart, ruleAEnd, undefined, "[]")
      ) {
        isOverlapping = true;
      }

      if (
        ruleAStart &&
        ruleAStart.isBetween(ruleBStart, ruleBEnd, undefined, "[]")
      ) {
        isOverlapping = true;
      }

      if (
        !isOverlapping &&
        ruleBEnd &&
        ruleBEnd.isBetween(ruleAStart, ruleAEnd, undefined, "[]")
      ) {
        isOverlapping = true;
      }

      if (
        !isOverlapping &&
        ruleAEnd &&
        ruleAEnd.isBetween(ruleBStart, ruleBEnd, undefined, "[]")
      ) {
        isOverlapping = true;
      }

      if (!isOverlapping && !ruleAEnd && !ruleBEnd) {
        isOverlapping = true;
      }
      if (
        !isOverlapping &&
        (ruleBStart && ruleBStart.isSame(ruleAEnd) ||ruleBEnd && ruleBEnd.isSame(ruleAStart))
      ) {
        isOverlapping = true;
      }

      return isOverlapping;
    };

    lineEditService.isSamePriceRules = function(ruleA, ruleB, compareOnlyOverlapping) {
      const isPrivateTransfer = lineEditService.line.privateTransfer.isActivated;
      const isFromStationTheSame = checkEquality(ruleB.fromStations, ruleA.fromStations);
      const isToStationTheSame = checkEquality(ruleB.toStations, ruleA.toStations);
      const isPassengerTypeTheSame = checkEquality(ruleB.passengerTypes, ruleA.passengerTypes);
      const isDowsTheSame = checkEquality(ruleB.dows, ruleA.dows);
      let isDepartureTimeTheSame =
        (!ruleA.departureTime || ruleA.departureTime.length === 0) &&
        (!ruleB.departureTime || ruleB.departureTime.length === 0);
      let isTimeIntervalsTheSame =
        (!ruleA.timeIntervals || ruleA.timeIntervals.length === 0) &&
        (!ruleB.timeIntervals || ruleB.timeIntervals.length === 0);

      (ruleA.timeIntervals || []).forEach((interval) => {
        isTimeIntervalsTheSame = (ruleB.timeIntervals || []).find(
          (int) => int.start === interval.start && int.end === interval.end
        );
      });

      (ruleA.departureTime || []).forEach((timeA) => {
        isDepartureTimeTheSame = (ruleB.departureTime || []).find(
          (timeB) => timeA === timeB
        );
      });

      let isSameParams =
      (!isPrivateTransfer ? isDepartureTimeTheSame : true)  &&
      isFromStationTheSame &&
      isToStationTheSame &&
      isDowsTheSame &&
      (isPrivateTransfer ? isTimeIntervalsTheSame : true) &&
      isPassengerTypeTheSame;

      let isSameRule = isSameParams;
      if (!compareOnlyOverlapping && isSameRule) {
        if ((ruleA.start && !ruleB.end) || (!ruleA.start && ruleB.end)) {
          isSameRule = false;
        }
        if (ruleA.start && ruleB.start && moment(ruleA.start).format('YYYY-MM-DD') !== moment(ruleB.start).format('YYYY-MM-DD')) {
          isSameRule = false;
        }
        if (ruleA.end && ruleB.end && moment(ruleA.end).format('YYYY-MM-DD') !== moment(ruleB.end).format('YYYY-MM-DD') ) {
          isSameRule = false;
        }
      } else {
        if (!isSameParams) return false;
        isSameRule = lineEditService.isOverlappingRules(ruleA, ruleB);
      }
      return isSameRule;
    }

    lineEditService.showHasOverlappingRulesModal = function (
      callback,
      overlappingRules
    ) {
      $uibModal
        .open({
          templateUrl: "views/are-you-sure-modal.html",
          controller: "AreYouSureCtrl",
          resolve: {
            text: [
              function () {
                return {
                  subtitle:
                    'The rule you are trying to add is overlapping with other existing rules. By clicking "Yes" you will override existing rules with the new rule.',
                  overlappingRules,
                };
              },
            ],
            title: [
              function () {
                return "Found overlapping rules";
              },
            ],
          },
        })
        .result.then(function (response) {
          if (response) {
            callback();
          }
        });
    };

    function removeOverlappingRules(overlappingRules) {
      overlappingRules.forEach((rule) => {
        lineEditService.line.priceExceptions =
          lineEditService.line.priceExceptions.filter(
            (existingRule) => existingRule._id !== rule._id
          );
      });
    }

    lineEditService.saveEditedPriceRule = function(priceRule, result) {
      if (result && result.apply && result.rule) {
        const callback = function() {
          let ruleToAdd = {};
          Object.assign(ruleToAdd, result.rule);
          if (
            result.overlappingExistingRules &&
            result.overlappingExistingRules.length > 0
          ) {
            removeOverlappingRules(result.overlappingExistingRules);
          }
          let ruleIndex = lineEditService.line.priceExceptions.findIndex(rule => rule._id === priceRule._id);
          lineEditService.line.priceExceptions[ruleIndex] = ruleToAdd;
          lineEditService.refreshPriceRules();
        }
        if (
          result.overlappingExistingRules &&
          result.overlappingExistingRules.length > 0
        ) {
          lineEditService.showHasOverlappingRulesModal(callback, result.overlappingExistingRules);
        } else {
          callback();
        }
      }
    }

    lineEditService.editPriceRule = function(priceRule, params) {
      let currRule = {};
      Object.assign(currRule, priceRule);
      $uibModal.open({
        templateUrl: 'views/lines/add-price-rule.html',
        controller: 'AddPriceRuleCtrl',
        resolve: {
          lineEditService: [function(){ return lineEditService }],
          ruleToEdit: [function(){ return priceRule }],
          fixedRulesParams: [
          function() {
            if (params && Object.keys(params)) {
              return {
                fromStations: params.fromStations,
                toStations: params.toStations,
                time: params.time,
                start: params.start,
                end: params.end,
                dows: params.dows,
                timeIntervals: params.timeIntervals,
              }
            } else return null;
          }
          ]
        },
      })
      .result
      .then(function(result) {
        lineEditService.saveEditedPriceRule(priceRule, result);
      });
    }

    lineEditService.savePriceRule = function (result) {
      if (result && result.apply && result.rule) {

        const addRule = function () {
          if (
            result.overlappingExistingRules &&
            result.overlappingExistingRules.length > 0
          ) {
            removeOverlappingRules(result.overlappingExistingRules);
          }
          lineEditService.line.priceExceptions.push(result.rule);
          lineEditService.refreshPriceRules();
        };

        const callback = function () {
          if (
            result.overlappingExistingRules &&
            result.overlappingExistingRules.length > 0
          ) {
            lineEditService.showHasOverlappingRulesModal(
              addRule,
              result.overlappingExistingRules
            );
          } else {
            addRule();
          }
        };

        if (result && result.event) {
          let isStartTheSame = true;
          let isEndTheSame = true;
          const eventStart = moment(result.event.start).startOf("day");
          const ruleStart =
            result.rule.start && moment(result.rule.start).startOf("day");
          const ruleEnd =
            result.rule.end && moment(result.rule.end).endOf("day");
          if (ruleStart) {
            isStartTheSame =
              eventStart.isAfter(ruleStart) || eventStart.isSame(ruleStart);
          }
          if (ruleEnd) {
            isEndTheSame =
              eventStart.isBefore(ruleEnd) || eventStart.isSame(ruleEnd);
          }
          if (!isStartTheSame || !isEndTheSame) {
            $uibModal
              .open({
                templateUrl: "views/are-you-sure-modal.html",
                controller: "AreYouSureCtrl",
                resolve: {
                  text: [
                    function () {
                      return "Date of the event is not inside rule date range. Do you want to add the rule?";
                    },
                  ],
                  title: [
                    function () {
                      return "Are you sure?";
                    },
                  ],
                },
              })
              .result.then(function (response) {
                if (response) {
                  callback();
                }
              });
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };

    lineEditService.createPriceRule = function (params) {
      $uibModal
        .open({
          templateUrl: "views/lines/add-price-rule.html",
          controller: "AddPriceRuleCtrl",
          resolve: {
            lineEditService: [
              function () {
                return lineEditService;
              },
            ],
            ruleToEdit: [
              function () {
                return null;
              },
            ],
            fixedRulesParams: [
              function () {
                if (params && Object.keys(params)) {
                  return {
                    fromStations: params.fromStations,
                    toStations: params.toStations,
                    time: params.time,
                    start: params.start,
                    end: params.end,
                    dows: params.dows,
                    timeIntervals: params.timeIntervals,
                  };
                } else return null;
              },
            ],
          },
        })
        .result.then(function (result) {
          lineEditService.savePriceRule(result);
        });
    };

    lineEditService.removePriceRule = function(priceRule) {
      $uibModal
      .open({
        templateUrl: 'views/are-you-sure-modal.html',
        controller: 'AreYouSureCtrl',
        resolve: {
          text: [function () { return null }],
          title: [function () { return 'Delete Price Rule'; }]
        }
      })
      .result
      .then(function (response) {
        if (response) {
          lineEditService.line.priceExceptions =
            lineEditService.line.priceExceptions.filter(
              (rule) => rule._id !== priceRule._id
            );
        }
      });
    }

    function checkStationsEquality(ruleStations, stations) {
      let isStationsTheSame = false;
      (ruleStations || []).forEach(station => {
        const index = stations.findIndex(st => st._id === station.id)
        if (index > -1) {
          isStationsTheSame = true;
        }
      });
      return isStationsTheSame;
    }

    lineEditService.refreshPriceRulesOnLocalLevel = function (
      date,
      fromStations,
      toStations
    ) {
      const priceRules = (
        lineEditService.line.priceExceptions || []
      ).filter((rule) => {
        const ruleStart = rule.start && moment(rule.start).startOf("day");
        const ruleEnd = rule.end && moment(rule.end).endOf("day");
        const time = date.format('HH:mm');

        const isPrivateTransfer = lineEditService.line.privateTransfer.isActivated;

        let isTimeTheSame = true;
        let isTimeIntervalTheSame = true;
        let isStartTheSame = true;
        let isEndTheSame = true;
        let isDowsTheSame = true;
        let isFromStationTheSame = true;
        let isToStationTheSame = true;

        if (rule.departureTime && rule.departureTime.length && !isPrivateTransfer) {
          isTimeTheSame = rule.departureTime.includes(time);
        }

        if (
          isPrivateTransfer &&
          rule.timeIntervals &&
          rule.timeIntervals.length
        ) {
          isTimeIntervalTheSame = rule.timeIntervals.some((interval) => {
            const intervalStart = moment(interval.start, "HH:mm");
            const selectedTripTime = moment(time, "HH:mm");
            return selectedTripTime.isSame(intervalStart);
          });
        }

        if (ruleStart) {
          isStartTheSame = date.isAfter(ruleStart) || date.isSame(ruleStart);
        }

        if (ruleEnd) {
          isEndTheSame = date.isBefore(ruleEnd) || date.isSame(ruleEnd);
        }

        if (rule.fromStations && rule.fromStations.length) {
          isFromStationTheSame = checkStationsEquality(
            rule.fromStations,
            fromStations
          );
        }

        if (rule.toStations && rule.toStations.length) {
          isToStationTheSame = checkStationsEquality(
            rule.toStations,
            toStations
          );
        }

        if (rule.dows && rule.dows.length) {
          isDowsTheSame = rule.dows.find(dow => dow.id === date.day());
        }

        return (
          isTimeTheSame &&
          isStartTheSame &&
          isEndTheSame &&
          isFromStationTheSame &&
          isToStationTheSame &&
          isTimeIntervalTheSame &&
          isDowsTheSame
        );
      });
      return priceRules;
    };

    lineEditService.changePrice = function(price, CurrenciesService) {
      if (price.isAmountByMarkup) {
        price.amountInSupplierCurrency = CurrenciesService.convertToFixed(price.costInSupplierCurrency * ((price.markup + 100 )/ 100));
      } else {
        var tempMarkup = 0;
        if (price.costInSupplierCurrency === 0){
          tempMarkup = 0;
        } else {
          tempMarkup = (((price.amountInSupplierCurrency - price.costInSupplierCurrency) / price.costInSupplierCurrency) * 100);
        }
        if (tempMarkup === Infinity || tempMarkup === -Infinity || !tempMarkup){
          tempMarkup = 0;
        }

        price.markup = CurrenciesService.convertToFixed(tempMarkup);
      }

      price.amountInSupplierCurrency = (price.amountInSupplierCurrency * 1) || 0;
      price.costInSupplierCurrency = (price.costInSupplierCurrency * 1) || 0;
      price.markup = (price.markup * 1) || 0;
      price.amount = CurrenciesService.convertWithFixedPrice(price.amountInSupplierCurrency, price.supplierCurrency, 'USD');
      price.cost = CurrenciesService.convertWithFixedPrice(price.costInSupplierCurrency, price.supplierCurrency, 'USD');
      price.isAmountByMarkup = price.isAmountByMarkup || false;
      price.supplierCurrency = price.supplierCurrency + '';
      return price;
    }

    function getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    function formatEvents(line) {
      if (line.trips && line.trips.length) {
        line.trips.filter(function(trip){return trip.tripType === 'single';}).forEach(function (trip) {
          const full = [];
          if (trip.isFullyBooked){
            full.push(trip.departureDay);
          }
          const event = {
            _id: trip.eventId,
            title: 'From A to B',
            start: moment(trip.departure.date).format(),
            end: moment(trip.arrival.date).format(),
            stick: true,
            className: 'newtask',
            color: getRandomColor(),
            id: uuid.v4(),
            currentTimezone: trip.departure.timezone,
            full: full,
            type: 'single',
          };
          if (trip.transferId.toString() === line.aToBTransferId.toString()) {
            line.schedule.aToBEvents.push(event);
          } else if (trip.transferId.toString() === line.bToATransferId.toString()) {
            line.schedule.bToAEvents.push(event);
          }
        });
      }
    }

    lineEditService.extraTypes = {
      'upgrade': 'Upgrade'
    };

    lineEditService.pickupDropoffTypes = [
      'dropoff_hotel',
      'dropoff_airport',
      'pickup_airport',
      'pickup_hotel'
    ];

    lineEditService.fullAllocationPolicy = {
      pending: {
        key: 'pending',
        text: 'Every new booking should create with Pending status'
      },
      full: {
        key: 'full',
        text: 'Trip will become fully booked'
      }
    };

    lineEditService.isPickupDropoffExtra = function(extra) {
      return this.pickupDropoffTypes.includes(extra.extraType);
    };

    lineEditService.isHotelPickupDropoffExtra = function(extra) {
      return this.isPickupDropoffExtra(extra) && extra.extraType.indexOf('hotel') >= 0;
    };

    lineEditService.isPickupExtra = function(extra) {
      return extra.extraType.indexOf('pickup') >= 0;
    };

    lineEditService.addExtra = function(extraType) {
      var extra = {
        label: this.extraTypes[extraType],
        inputLabel: '',
        price: {
          amount: 0,
          cost: 0,
          amountInSupplierCurrency: 0,
          costInSupplierCurrency: 0,
          markup: this.line.defaultPrice.markup,
          isAmountByMarkup: this.line.defaultPrice.isAmountByMarkup,
          supplierCurrency: this.line.supplierCurrency,
        },
        priceLevel: '',
        mandatory: false,
        needInput: true,
        extraType: extraType,
        perPassenger: false,
        allowPredefinedData: false,
        usePredefinedData: false,
        data: []
      };

      if (this.isHotelPickupDropoffExtra(extra)) {
        extra.allowPredefinedData = true;
        this.addPickupDropoffDataToExtra(extra);
      }

      if (extraType === 'upgrade') {
        extra.upgrade = '';
        extra.needInput = false;
      }

      this.line.extras.push(extra);
    };

    lineEditService.addVideo = function() {
      this.line.videos.push({
        platform: 'youtube',
        src: '',
        start: 0,
        end: 0,
      });
    };

    lineEditService.removeVideo = function(index) {
      this.line.videos.splice(index, 1);
    };

    lineEditService.addPickupDropoffDataToExtra = function(extra) {
      extra.data.push({
        name: '',
        time: 0,
        beforeAfter: 'before',
      });
    };

    lineEditService.removePickupDropoffDataFromExtra = function(extra, index) {
      extra.data.splice(index, 1);
    };

    lineEditService.deleteExtra = function(extra) {
      var id = extra._id;

      // no id means extra is newly created
      // and not saved yet
      if (!id) {
        var index = this.line.extras.findIndex(function(object) {
          return object === extra;
        });

        if (index !== -1) {
          this.line.extras.splice(index, 1);
        }

        return;
      }

      var isDisplayBoth = extra.priceLevel === 'display_both';

      extra.deleted = true;

      if (isDisplayBoth) {
        this.line.extras = this.line.extras.map(function(extra) {
          if (extra.duplicateOf === id) {
            extra.deleted = true;
          }

          return extra;
        });
      }
    };

    lineEditService.retrieveServiceForLine = function(lineId) {
      var self = this;
      return InventoryRestangular
        .one('lines', lineId)
        .get({ includeTransferMappings: window.user && window.user.shouldSeeFullAdmin })
        .then(function(line) {
          self.line = line;
          self.originalCompany = line.company;
          self.originalSupplier = line.supplier;
          formatEvents(line);
          formatPublishedAt(line);
          formatLuggage(line);

          if (!self.line.transferMappings){
            self.line.transferMappings = [];
          }

          // Add ecommerce for previous lines
          if (!line.ecommerce) {
            self.line.ecommerce = {};
          }

          self.line.extras.forEach(function(extra) {
            extra.label = self.extraTypes[extra.extraType];

            // update hotel pickup/drop off extras
            if (lineEditService.isHotelPickupDropoffExtra(extra) && _.isEmpty(extra.data)) {
              extra.allowPredefinedData = true;
              lineEditService.addPickupDropoffDataToExtra(extra);
            }
          });

          // Add types for pictures/template
          self.line.pictures.forEach(function(picture) {
            picture.type = 'picture';
          });

          if (self.line.ticketTemplate) {
            self.line.ticketTemplate.type = 'template';
          }

          if (!self.line.fullAllocationPolicy) {
            self.line.fullAllocationPolicy = self.fullAllocationPolicy.pending.key;
          }

          if (!self.line.fullyBookedByAutoApprovePolicy) {
            self.line.fullyBookedByAutoApprovePolicy = self.fullAllocationPolicy.pending.key;
          }

          if (!self.line.trips) {
            self.line.trips = [];
          }

          if (!self.line.deletedEventsIds){
            self.line.deletedEventsIds = [];
          }

          self.line.updatedTrips = [];

          var event;
          if (self.line.schedule.aToBEvents && self.line.schedule.aToBEvents.length > 0) {
            event = self.line.schedule.aToBEvents[0];
            self.timezoneHoursDifference = moment().tz(event.currentTimezone).format('Z');
          }
          else if (self.line.schedule.bToAEvents && self.line.schedule.bToAEvents.length > 0) {
            event = self.line.schedule.bToAEvents[0];
            self.timezoneHoursDifference = moment().tz(event.currentTimezone).format('Z');
          }

          if (!self.line.automaticApprovalAmountType) {
            self.line.automaticApprovalAmountType = 'unlimited';
          }

          if (!self.line.automaticApprovalCutoff || (moment.duration(self.line.automaticApprovalCutoff).asHours() < moment.duration(self.line.bookBefore).asHours())) {
            self.line.automaticApprovalCutoff = self.line.bookBefore;
          }

          if (!self.line.bookingFee || !self.line.bookingFee.price){
            self.line.bookingFee = {
              isActive: false,
                isPerPassenger: false,
                description: 'Booking fee',
                price: createPrice(self.line.defaultPrice.supplierCurrency)
            };
          }

          return self;
        });
    };


    lineEditService.duplicateLine = function(lineId) {
      var self = this;

      return InventoryRestangular
        .one('lines', lineId)
        .get()
        .then(function(line) {
          self.line = Object.assign({}, line.plain(), {
            published: false,
            ecommerce: {}
          });

          delete self.line.publishedAt;
          delete self.line._id;
          delete self.line.id;
          delete self.line.denomination;
          delete self.line.denominationSlug;
          delete self.line.__v;
          delete self.line.createdAt;
          delete self.line.updatedAt;
          delete self.line.aToBTransferId;
          delete self.line.bToATransferId;
          delete self.line.isScrapingAvailability;
          formatEvents(line);
          self.line.trips = [];
          self.line.deletedEventsIds = [];
          self.line.updatedTrips = [];

          if (self.line.isSupplierApi){
            self.line.schedule = {
              aToBEvents: [],
              bToAEvents: []
            };
            self.line.automaticApproval = false;
            self.line.isSupplierApi = false;
          }


          if (self.additionalServices && self.additionalServices.customServices && self.additionalServices.customServices.length){
            self.additionalServices.customServices.forEach(function(customService){
              delete customService._id;
            });
          }



          self.line.schedule.aToBEvents.forEach(function (event) {
            delete event._id;
          });
          self.line.schedule.bToAEvents.forEach(function (event) {
            delete event._id;
          });

          formatLuggage(self.line);

          // Add ecommerce for previous lines
          if (!line.ecommerce) {
            self.line.ecommerce = {};
          }

          // Add types for pictures/template
          self.line.pictures.forEach(function(picture) {
            picture.type = 'picture';
          });

          if (self.line.ticketTemplate) {
            self.line.ticketTemplate.type = 'template';
          }

          return self;
        });
    };

    lineEditService.addForm = function(form) {
      this.form = form;
    };

    lineEditService.setActiveTab = function(index) {
      this.activeTab = index;
    };


    lineEditService.createLine = function() {
      this.line = {
        defaultPrice: createPrice(),
        supplierCurrency: 'USD',
        extraOptionDefinitions: [],
        passengerExtraInfoDefinitions: [],
        keyDetails: [],
        schedule: {
          aToBEvents: [],
          bToAEvents: []
        },
        approvalInputDefinitions: {},
        ecommerce: {},
        extras: [],
        trips: [],
        updatedTrips: [],
        deletedEventsIds: [],
        videos: [],
        needExtraInfo: true,
        aToBMultiStations: [],
        bToAMultiStations: [],
        bookingFee: {
          isActive: false,
          isPerPassenger: false,
          description: 'Booking fee',
          price: createPrice()
        },
        manualBookings: {
          operations: false,
          support: false
        }
      };

      return this;
    };


    // DEPRECATED!
    lineEditService.addExtraOptionDefinition = function () {
      this.line.extraOptionDefinitions.push({
        label: '',
        needInput: true,
        perBooking: false,
        inputLabel: '',
        icon: {},
        price: createPrice()
      });
    };

    // DEPRECATED!
    lineEditService.removeExtraOptionDefinition = function(extraOptionDefinition) {
      this.line.extraOptionDefinitions = this.line.extraOptionDefinitions.filter(function(elm) {
        return elm !== extraOptionDefinition;
      });
    };

    lineEditService.validateStations = function(stations) {
      if (stations && stations.length){
        var allStationValid = stations.every(function (station){
          return station && station.stationId && station.stationId._id;
        });
        if (!allStationValid){
          throw new Error('stationList not valid');
        }
      }
    };

    lineEditService.checkFormValidity = function() {
      if (this.form.$invalid) {
        this.form.denomination.$setTouched();
        this.form.minPassengers.$setTouched();
        this.form.company.$setTouched();
        this.form.type.$setTouched();
        this.form.lineClass.$setTouched();
        this.form.isRefundable.$setTouched();
        this.form.isFlexibleCancellation.$setTouched();
        this.form.isAmendable.$setTouched();
        for (var i = 0; i < lineEditService.line.amendments.length; i++) {
          this.form['amendmentFee_'+ i].$setTouched();
          this.form['amendmentTimeBefore_'+ i].$setTouched();
        }
        for (var j = 0; j < lineEditService.line.cancellations.length; j++) {
          this.form['cancellationFee_'+ j].$setTouched();
          this.form['cancellationTimeBefore_'+ j].$setTouched();
        }
        $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Form is invalid.' });

        return false;
      } else {

        if (lineEditService.line.videos && lineEditService.line.videos.length) {
          for (var k = 0; k < lineEditService.line.videos.length; k++) {
            var video = lineEditService.line.videos[k];
            var validateRegex = new RegExp(/^[^\&\/\?\.\s]+$/);
            if (!validateRegex.test(video.src)) {
              $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Invalid video ID in youtube video number ' + (k+1) });
              return false;
            }
            if (video.start === null) {
              video.start = 0;
            }
            if (video.end === null) {
              video.end = 0;
            }
          }
        }

        if (!lineEditService.line.stationA || !lineEditService.line.stationB){
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Primary stations are required' });
          return false;
        }

        if (lineEditService.line.voucherAlert && !!lineEditService.line.voucherAlert.title !== !!lineEditService.line.voucherAlert.text){
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Voucher alert should contain both title and text' });
          return false;
        }

        if (!lineEditService.line.defaultPrice || !lineEditService.line.defaultPrice.costInSupplierCurrency || !lineEditService.line.defaultPrice.amountInSupplierCurrency){
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Please defined line cost' });
          return false;
        }

        lineEditService.validateStations(lineEditService.line.aToBMultiStations);
        lineEditService.validateStations(lineEditService.line.bToAMultiStations);

        // make sure all predefined data in extras list is populated.
        var emptyExtraData = lineEditService.line.extras.some(function(item) {
          var data = item.data || [];
          if (item.deleted || !item.usePredefinedData || !data.length) {
            return false;
          }

          return data.some(function(d) {
            return d.name === '' && !d.time;
          });
        });

        if (emptyExtraData) {
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Data given on Hotel Pickup or Drop Off can\'t be empty' });
          return false;
        }

        // validate cutoffs
        var lineCutoffDuration = moment.duration(lineEditService.line.bookBefore).asHours();
        var lineAllocationCutoffDuration = moment.duration(lineEditService.line.allocationCutoff).asHours();
        var lineAutoApprovalCutoffDuration = moment.duration(lineEditService.line.automaticApprovalCutoff).asHours();

        if (lineAllocationCutoffDuration > lineCutoffDuration) {
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Prepaid cutoff can\'t be longer than line cutoff' });
          return false;
        }

        if (lineEditService.line.automaticApproval && lineCutoffDuration > lineAutoApprovalCutoffDuration) {
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Line cutoff can\'t be longer than automatic approval cutoff' });
          return false;
        }

        if (lineEditService.line.automaticApproval && lineEditService.line.automaticApprovalAmountType === 'limited' && !lineEditService.line.automaticApprovalAmount) {
          $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Line automatic approval amount limit can\'t be empty' });
          return false;
        }

        // validate schedule
        const isPrivateTransfer = lineEditService.line.privateTransfer && lineEditService.line.privateTransfer.isActivated;
        if (isPrivateTransfer &&
          [lineEditService.line.schedule.aToBEvents, lineEditService.line.schedule.bToAEvents].some(hasCrossDayEvent)) {
          $rootScope.$emit('notify', { type: 'error', title: 'Invalid', message: 'Line schedule can\'t have cross-day events' });
          return false;
        }

        return true;
      }
    };

    lineEditService.addPrimaryToMultiStation = function (originalStation, newStation, multiStationList, isBoarding, isDropOff){
      var copyOfNewStation = _.cloneDeep(newStation);
      if (isBoarding){
        copyOfNewStation.isBoarding = true;
      }

      if (isDropOff){
        copyOfNewStation.isDropoff = true;
      }


      var foundIndex = multiStationList.findIndex(function(item){return  originalStation && item.stationId._id === originalStation._id && item.isPrimary === true;});
      if (foundIndex > -1){
        multiStationList[foundIndex].stationId = copyOfNewStation.stationId;
      } else {
        multiStationList.push(copyOfNewStation);
      }
      multiStationList.sort(function (a,b){
        var result = a.timeOffsetInMinutes - b.timeOffsetInMinutes;
        if (isDropOff){
          result = result - 1;
        }
        return result;
      });
    };

    function swapStationInMultiStationList(newStationA, newStationB, multiStation){
      const oldStationBIndex = multiStation.findIndex(function(station){
        return station.stationId._id === newStationA._id;
      });
      const oldStationAIndex =  multiStation.findIndex(function(station){
        return station.stationId._id === newStationB._id;
      });
      if (oldStationBIndex > -1 && oldStationAIndex > -1){
        multiStation[oldStationBIndex].stationId = newStationB;
        multiStation[oldStationAIndex].stationId = newStationA;
      } else {
        console.error('multiStationList has problem when swapping stations');
      }
    }

    lineEditService.swapPrimaries = function (){
      var oldStationA = lineEditService.line.stationA;
      var oldStationB = lineEditService.line.stationB;
      lineEditService.line.stationA = oldStationB;
      lineEditService.line.stationB = oldStationA;
      swapStationInMultiStationList(oldStationB, oldStationA, lineEditService.line.aToBMultiStations);
      swapStationInMultiStationList(oldStationB, oldStationA, lineEditService.line.bToAMultiStations);
      lineEditService.line.schedule.aToBEvents.forEach(function(event){
        if (event.multiStations && event.multiStations.length){
          swapStationInMultiStationList(oldStationB, oldStationA, event.multiStations);
        }
      });
      lineEditService.line.schedule.bToAEvents.forEach(function(event){
        if (event.multiStations && event.multiStations.length){
          swapStationInMultiStationList(oldStationB, oldStationA, event.multiStations);
        }
      });
    };

    lineEditService.openEditPrimaryStationModal = function (originalStation, selector) {
      if (selector !== 'stationA' && selector !== 'stationB'){
        return;
      }

      var stationCopy;
      if (originalStation){
        stationCopy = _.cloneDeep(originalStation);
      }
      const isPrivateTransfer = lineEditService.line.privateTransfer && lineEditService.line.privateTransfer.isActivated
      $uibModal
        .open({
          templateUrl: 'views/lines/add-primary-station.html',
          controller: 'AddPrimaryStationCtrl',
          resolve: {
            station: [function() {
              return {
                stationId: stationCopy
              };
            }],
            isPrivateTransfer: isPrivateTransfer,
          }
        })
        .result
        .then(function(response) {
          if (response && response.station) {
            var oppositeSelector = selector === 'stationA' ? 'stationB' : 'stationA';
            if (lineEditService.line[oppositeSelector] && lineEditService.line[oppositeSelector]._id === response.station.stationId._id ){
              $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: 'Line cannot have the same station twice' });
              return;
            }
            lineEditService.line[selector] = response.station.stationId;
            lineEditService.addPrimaryToMultiStation(originalStation, response.station, lineEditService.line.aToBMultiStations, selector === 'stationA', selector === 'stationB');
            lineEditService.addPrimaryToMultiStation(originalStation, response.station, lineEditService.line.bToAMultiStations, selector === 'stationB', selector === 'stationA');
            lineEditService.line.schedule.aToBEvents.forEach(function(event){
              if (event.multiStations && event.multiStations.length) {
                lineEditService.addPrimaryToMultiStation(originalStation, response.station, event.multiStations, selector === 'stationA', selector === 'stationB');
              }
            });
            lineEditService.line.schedule.bToAEvents.forEach(function(event) {
              if (event.multiStations && event.multiStations.length) {
                lineEditService.addPrimaryToMultiStation(originalStation, response.station, event.multiStations, selector === 'stationB', selector === 'stationA');
              }
            });
          }
        });
    };
    lineEditService.checkToPromptBeforeSave = function() {
      const self = this;
      const isSupplierChanged = self.line.supplier && self.line._id ? self.line.supplier._id !== self.originalSupplier._id: false;
      if (self.line._id && self.line.supplier._id !== self.originalSupplier._id) {
        $uibModal
        .open({
          templateUrl: "views/are-you-sure-modal.html",
          controller: "AreYouSureCtrl",
          resolve: {
            text:[function () {
              return "The supplier of the line was changed and the currency of the line will be also changed"
            }],
            title: [function () { return 'Are you sure?'; }]
          },
        })
        .result.then(function (response) {
          if (response) {
            lineEditService.save();
          } else {
            $uibModalInstance.close();
          }
        })
      } else {
        lineEditService.save();
      }
    }

    const updatePassengersExtras = (productPassengersExtras = [], lineExtras = [], priceExceptions = []) => {
      const ageExtra = productPassengersExtras.find((extra) => extra.slug === "age");
      const birthday = productPassengersExtras.find(
        (extra) => extra.slug === "date_of_birth"
      );
      const hasPriceExceptionsWothPaxTypes = priceExceptions.find(
        (exception) =>
          exception.passengerTypes && exception.passengerTypes.length
      );
      const lineHasAgeOrBirthdayExtraDefined = lineExtras.find(
        (extra) => ageExtra && extra._id === ageExtra._id || birthday && extra._id === birthday._id
      );

      if (
        hasPriceExceptionsWothPaxTypes &&
        !lineHasAgeOrBirthdayExtraDefined
      ) {
        lineExtras.push(ageExtra);
      }
      return lineExtras;
    };

    lineEditService.save = function(eventName) {
      if (lineEditService.saveLock) {
        console.warn('line is performing save operation');
        return;
      }
      lineEditService.saveLock = true;
      if(lineEditService.manualBookings && !lineEditService.line.manualBookings.operations && !lineEditService.line.manualBookings.support) {
        $rootScope.$emit('notify', {type: 'error', title: 'Manual Bookings', message: "Manual Bookings is ON, but neither support or operations chosen" });
        lineEditService.saveLock = false;
        return;
      }
      try {
        if (!this.checkFormValidity()) {
          lineEditService.saveLock = false;
          return;
        }
      } catch (err) {
        console.error(err.message);
        $rootScope.$emit('notify', {type: 'error', title: 'Invalid', message: err.message });
        lineEditService.saveLock = false;
        return;
      }

      // TODO: Move from here, the logic should not be here
      if (!eventName) {
        eventName = this.line._id ? 'Line Updated' : 'Line Created';
      }

      var self = this;
      this.line.trips = [];

      var successMessage = this.line._id ? {type: 'success', title: 'Update', message: 'Line updated with success'} :
        {type: 'success', title: 'Create', message: 'Line created with Success'};

      this.line.passengerExtraInfoDefinitions = updatePassengersExtras(
        passengerExtraInfoDefinitions,
        this.line.passengerExtraInfoDefinitions,
        this.line.priceExceptions
      );
      this.line.luggage = saveLuggage(this.line)
      this.line.additionalServices = saveAdditionalServices(this.line);
      var promise = this.line._id ? this.line.put() : baseLines.post(this.line);

      return promise
        .then(function(savedLine) {
          lineEditService.saveLock = false;
          self.line = savedLine;

          formatPublishedAt(self.line);
          formatLuggage(self.line);
          self.line.updatedTrips = [];

          self.line.extras.forEach(function(extra) {
            extra.label = self.extraTypes[extra.extraType];
          });

          $rootScope.$emit('notify', successMessage);

          var eventProperties = {
            'Product ID': savedLine.ecommerce.productId,
            'Product Name': savedLine.ecommerce.name,
            'Supplier ID': savedLine.company._id,
            'Supplier Name': savedLine.company.name,
            'Cities': [savedLine.stationA.city.name, savedLine.stationB.city.name],
            'Countries': [savedLine.stationA.city.country],
            'Vehicle Type': savedLine.type
          };

          if (savedLine.stationA.city.country !== savedLine.stationB.city.country) {
            eventProperties.Countries.push(savedLine.stationB.city.country);
          }

          DataCollector.track(eventName, eventProperties);

          // report new fully-booked trips
          self.newBlockEvents.forEach(function (event) {
            var props =
              DataCollector.blockDeparturePropsGenerator.byLineAndEvent(self.line, event);
            DataCollector.trackBlockedDeparture(props);
          });
          self.newBlockEvents = [];

          $state.go('root.editLine', {lineId: savedLine._id}, {inherit: false, reload: true});

          return savedLine;
        })
        .catch(function(err) {
          lineEditService.saveLock = false;
          $rootScope.$emit('notify', {type: 'error', title: err.statusText, message: JSON.stringify(err.data) });
          if (lineEditService.line && lineEditService.line._id) {
            $state.go('root.editLine', {lineId: lineEditService.line._id}, {inherit: false, reload: true});
          }
        });
    };

    lineEditService.publish = function() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.line.published = true;

      return this.save('Line Published');
    };

    lineEditService.unpublish = function() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.line.published = false;

      return this.save('Line Unpublished');
    };

    lineEditService.getLineInConsumersUrl = function(direction) {
      var slug;
      var cityA;
      var cityB;


      if (this.line._id && (this.line.published)) {
        var url;
        cityA = this.line.stationA.city.slug;
        cityB = this.line.stationB.city.slug;
        var country = this.line.stationB.city.countrySlug;
        slug = this.line.slug;
        url = appConfig.frontendBrowserUrl + '/routes/' + country + '/';
        if (direction === 'aToB') {
          url += cityA + '-to-' + cityB + '/' + slug;
        } else {
          url += cityB + '-to-' + cityA + '/' + slug;
        }

        return url;
      } else {
        return null;
      }
    };

    lineEditService.uncache = function() {
      return this.line.one('uncache').remove()
        .then(function() {
          $rootScope.$emit('notify', {type: 'success', title: 'Uncache', message: 'Line removed from cache'});
        })
        .catch(function(err) {
          $rootScope.$emit('notify', {type: 'error', title: err.statusText, message: JSON.stringify(err.data) });
        });
    };

    return lineEditService;
  }];
}

'use strict';

/* exported
lineEditMenuCtrl
 */

function lineEditMenuCtrl($scope, lineEditService, user, InventoryRestangular, $uibModal, $state) {
  $scope.lineEditService = lineEditService;
  $scope.user = user;

  $scope.getAToBPreviewLink = function() {
    return lineEditService.getLineInConsumersUrl('aToB');
  };

  $scope.getBToAPreviewLink = function() {
    return lineEditService.getLineInConsumersUrl('bToA');
  };

  $scope.runSupplierApiSync = function(daysInAdvance) {
    InventoryRestangular.all('v1/supplier-api/' + lineEditService.line._id + '/run-sync').post({daysInAdvance: daysInAdvance})
      .then(function(){
        $scope.$emit('notify', {type: 'success', title: 'Sync', message: 'Synced'});
      })
      .catch(function(err){
        $scope.$emit('notify', {type: 'error', title: 'Error', message: 'Failed to Sync: ' + (err.data && err.data.message) || err.message || 'unknown error'});
      })
      .finally(function(){
        $state.reload();
      });
  };

  $scope.$watch('lineEditService.line.isSupplierApi', function (isSupplierApi) {
    if(isSupplierApi) {
      lineEditService.line.isScrapingAvailability = false;
    }
  });

  $scope.$watch('lineEditService.line.isScrapingAvailability', function (isScrapingAvailability) {
    if(isScrapingAvailability) {
      lineEditService.line.isSupplierApi = false;
    }
  });
};

 'use strict';

/* exported
addMultiStationCtrl
 */

function addMultiStationCtrl($scope, $uibModalInstance, station, hideEditStation, isPrivateTransfer, supplierCurrency, stationType, primeryDropOffOffset, defaultMarkup, appConfig) {
  $scope.hideEditStation = hideEditStation || false;
  $scope.isNewPickupDropOff = appConfig.newPickupDropOff
  if (station) {
    $scope.station = station;
  } else {
    $scope.station = {
      stationId: undefined,
      timeOffsetInMinutes: 0,
      isPrimary: false,
      isBoarding: false,
      isDropoff: false,
      stationType
    };
  }
    if(!$scope.station.pickupDropoffAdditionalData){
      $scope.station.pickupDropoffAdditionalData = {
        showFlightDetails: !!($scope.station.stationType !=='stop' && $scope.station.stationId && $scope.station.stationId.locationType === 'airport'),
        price: {
        "supplierCurrency": supplierCurrency,
        "markup": defaultMarkup,
        "isAmountByMarkup": true,
        "cost":0,
        "amount":0,
        "amountInSupplierCurrency":0,
        "costInSupplierCurrency":0,
        "perPassenger":false,
        } 
      }
    }
    $scope.$watch('station.stationId', ()=>{
      if(!!$scope.station.stationId){
        $scope.station.pickupDropoffAdditionalData.showFlightDetails = !!($scope.station.stationType !=='stop' && $scope.station.stationId.locationType === 'airport')
      }
    })
  if($scope.station.stationType ==='pickupDropOff'){
    if($scope.station.isBoarding){
      $scope.station.pickupDropOff = 'pickup'
    }
    if($scope.station.isDropoff){
      $scope.station.pickupDropOff = 'dropOff'
    }
  }
  $scope.isPrivateTransfer = isPrivateTransfer;
  if(stationType){
    $scope.station.stationType = stationType
  }  

  $scope.stationType = stationType || station.stationType;
  $scope.getTitle = ()=>{
    let title = ''
    title += station ? 'Manage ' : 'Add '
    if($scope.station.isPrimary){
      title += 'Primary '
    }

    if($scope.stationType){
      title += stationType === 'stop' ? 'Stop ' : 'Pickup/Drop-off '
    }

    title += 'Station'

    return title;
  }
  $scope.getStationTypeText = ()=>{
    let title = ''
    if($scope.station.isPrimary){
      title += 'Primary '
    }
    if($scope.station.isBoarding){
      title += 'Pickup '
    }
    if($scope.station.isDropoff){
      title += 'Drop-off'
    }

    title += 'Station'

    return title;
  }
  $scope.set = function () {
    const errorMessage = ['Form has errors'];

    var isInvalid = this.addStationForm.$invalid;
    if ($scope.station.pickupDropOff) {
      switch($scope.station.pickupDropOff){
        case 'pickup':
          $scope.station.isBoarding = true;
          $scope.station.isDropoff = false;
          break;
        case 'dropOff':
          $scope.station.isDropoff = true;
          $scope.station.isBoarding = false;
          break;
      }
    } 
    if($scope.newPickupDropOff && $scope.station.stationId.type === 'area' && (!$scope.station.pickupDropoffAdditionalData || !$scope.station.pickupDropoffAdditionalData.selectionType)){
      errorMessage.push('Select Location Selection Method.');
      isInvalid = true;
    }
    if($scope.station.stationId && $scope.station.stationType === 'stop' && $scope.station.stationId.type === 'area'){
      errorMessage.push('stop stations can not with geometric type \'Area\'');
      isInvalid = true;
    }
    if (!$scope.station.isBoarding && !$scope.station.isDropoff) {
      errorMessage.push('boarding or dropoff is required');
      isInvalid = true;
    }
    if ($scope.isPrivateTransfer && $scope.station.timeOffsetInMinutes > MAX_MULTI_STATION_OFFSET) {
      errorMessage.push(`max offset is 23:59 hours (${MAX_MULTI_STATION_OFFSET} minutes)`);
      isInvalid = true;
    }
    if($scope.station.stationType === 'stop' &&( $scope.station.timeOffsetInMinutes > primeryDropOffOffset ||  $scope.station.timeOffsetInMinutes < 0)){
      errorMessage.push('only values that are greater than 0, and lower than Primary Drop-off Station Time Offset allowed.');
      isInvalid = true;
    }
    if($scope.station.pickupDropOff === 'pickup' && $scope.station.timeOffsetInMinutes > 0){
      errorMessage.push('pickup requires offset lower or equal to 0');
      isInvalid = true;
    }
    if($scope.station.pickupDropOff === 'dropOff' && $scope.station.timeOffsetInMinutes < primeryDropOffOffset){
      errorMessage.push('Drop-Off requires offset greater than Primary Drop-off Station Time Offset.');
      isInvalid = true;
    }
    if (isInvalid) {
      $scope.$emit('notify', {type: 'error', title: 'Error', message: errorMessage.join('<br>')});
      return false;
    }
    if ($scope.station.stationId.locationType !== 'airport' || $scope.station.stationType ==='stop') {
      $scope.station.pickupDropoffAdditionalData.showFlightDetails= false;
    }
    
    if ($scope.station.stationId.type !== 'area' || $scope.station.stationType ==='stop') {
      $scope.station.pickupDropoffAdditionalData.selectionType = undefined
      $scope.station.pickupDropoffAdditionalData.points = []
    }

    if($scope.station.pickupDropoffAdditionalData.selectionType !== 'points'){
      $scope.station.pickupDropoffAdditionalData.points = []
    }

    $uibModalInstance.close({station: $scope.station});
  };
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}

'use strict';

/* exported
editHotelListCtrl
 */

function editHotelListCtrl($scope, $uibModalInstance, station) {
  $scope.station = station;
  if ($scope.station.pickupDropoffAdditionalData && (!$scope.station.pickupDropoffAdditionalData.points || !$scope.station.pickupDropoffAdditionalData.points.length)) {
    $scope.station.pickupDropoffAdditionalData.points = [{
      name: '',
      time: 0,
      beforeAfter: 'before',
    }]
  }
  $scope.addHotel = () => {
    $scope.station.pickupDropoffAdditionalData.points.push({
      name: '',
      time: 0,
      beforeAfter: 'before',
    });
  }
  $scope.deleteHotel = (index) => {
    $scope.station.pickupDropoffAdditionalData.points.splice(index, 1);
  }
  $scope.handleFileSelect = (evt) => {
    const file = evt.target.files[0];
    const fileExt = file.name.split('.')[1];

    if (fileExt !== 'csv') {
      alert('unsupported file type')
      return;
    }
    const reader = new FileReader();

    reader.onload = function (event) {
      const csvData = event.target.result;
  
      var parsedData = parseCSV(csvData);
      parsedData.forEach((hotel) => {
        $scope.station.pickupDropoffAdditionalData.points.push(hotel);
      });
      $scope.$apply(); // Update the scope
    };

    reader.onerror = function () {
      $scope.$emit('notify', { type: 'error', title: 'Error', message: 'Error reading file' });
    };

    reader.readAsText(file);
  };


  function parseCSV(data) {
    var allTextLines = data.split(/\r\n|\n/);
    const hotels = [];

    for (var i = 0; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(',');
      if (data.length === 2) {
        const hotel = {
          name: data[0],
          time: parseInt(data[1]),
          beforeAfter: data[1] < 0 ? 'after' : 'before'
        }
        hotels.push(hotel);
      }
    }
    return hotels;
  }


  $scope.set =  () => {
    const errorMessage = ['Form has errors'];
    var isInvalid;
    $scope.station.pickupDropoffAdditionalData.points.forEach((hotel)=>{
      if(!hotel.name){
        errorMessage.push('the hotel name can not be empty')
        isInvalid = true
      }
    })
    if (isInvalid) {
      $scope.$emit('notify', { type: 'error', title: 'Error', message: errorMessage.join('<br>') });
      return false;
    }
    $uibModalInstance.close({ station: $scope.station });
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}

'use strict';

/* exported
addPrimaryStationCtrl
 */

function addPrimaryStationCtrl($scope, $uibModalInstance, station, isPrivateTransfer) {
  if (station) {
    $scope.station = station;
  } else {
    $scope.station = {
      stationId: undefined,
    };
  }

  $scope.station.isBoarding = $scope.station.isBoarding || false;
  $scope.station.isDropoff = $scope.station.isDropoff || false;
  $scope.station.isPrimary = true;
  $scope.station.stationType = 'primary';
  $scope.station.timeOffsetInMinutes = $scope.station.timeOffsetInMinutes || 0;
  $scope.station.pickupDropoffAdditionalData = $scope.station.pickupDropoffAdditionalData || {
    showFlightDetails: true,
    points: [],
    note: '',
  }
  $scope.isPrivateTransfer = isPrivateTransfer;

  $scope.set = function () {
    const errorMessage = ['Form has errors'];

    var isInvalid = this.addStationForm.$invalid;

    if ($scope.isPrivateTransfer && $scope.station.timeOffsetInMinutes > MAX_MULTI_STATION_OFFSET) {
      errorMessage.push(`max offset is 23:59 hours (${MAX_MULTI_STATION_OFFSET} minutes)`);
      isInvalid = true;
    }

    if (isInvalid) {
      $scope.$emit('notify', {type: 'error', title: 'Error', message: errorMessage.join('<br>')});
      return false;
    }
    $uibModalInstance.close({station: $scope.station});
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}

'use strict';

/* exported
 removeAllTripsCtrl
 */

function removeAllTripsCtrl($scope, lineEditService, $uibModalInstance) {
  $scope.aToB = true
  $scope.bToA = true

  $scope.ok = function () {
    if($scope.aToB && $scope.bToA) {
      lineEditService.line.schedule.aToBEvents = [];
      lineEditService.line.schedule.bToAEvents = [];
      $('#inbound-schedule').fullCalendar('removeEvents');
      $('#outbound-schedule').fullCalendar('removeEvents');
    } else if ($scope.aToB && !$scope.bToA) {
      lineEditService.line.schedule.aToBEvents = [];
      $('#inbound-schedule').fullCalendar('removeEvents');
    } else if ($scope.bToA && !$scope.aToB) {
      lineEditService.line.schedule.bToAEvents = [];
      $('#outbound-schedule').fullCalendar('removeEvents');
    } 

    $uibModalInstance.close();
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel')
  }
}

"use strict";

/* exported
editPriceCtrl
 */

function editPriceCtrl(
  $scope,
  $uibModalInstance,
  lineEditService,
  CurrenciesService,
  title,
  price
) {
  const _price = {};
  Object.assign(_price, price);
  $scope.model = {
    title,
    price: _price,
  };
  $scope.$watch(
    "model.price.costInSupplierCurrency + model.price.amountInSupplierCurrency + model.price.isAmountByMarkup + model.price.markup",
    function () {
      $scope.model.price = lineEditService.changePrice($scope.model.price, CurrenciesService);
    }
  );
  $scope.close = function () {
    $uibModalInstance.close({ price: $scope.model.price, apply: false });
  };
  $scope.apply = function () {
    $uibModalInstance.close({ price: $scope.model.price, apply: true });
  };
}

"use strict";

/* exported
addPriceRuleCtrl
 */

function addPriceRuleCtrl($scope, $rootScope, $uibModalInstance, ruleToEdit, fixedRulesParams) {
  $scope.fixedRulesParams = fixedRulesParams;
  $scope.ruleToEdit = ruleToEdit;
  $rootScope.$on("closeModal", function (event, data) {
    $uibModalInstance.close();
  });
  $rootScope.$on("cancelAddNewRule", function (event, data) {
    $uibModalInstance.close({
      apply: data.apply,
    });
  });
}

'use strict';

/*
exported
transferMappingLineDirective
 */

function transferMappingLineDirective(StringHelperService, lineEditService) {
  return {
    restrict: 'AE',
    templateUrl: 'views/lines/transfer-mapping-table-line.directive.html',
    scope: {
      transfers: '=?transfers',
      isExistingMappings: '=?isExistingMappings',
      onTransferMappingAction: '&',
      bookawaylines: '@',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      scope.filterByDepartureStation = '';
      scope.filterByArrivalStation = '';
      scope.filterByVehicleType = '';
      scope.filterByVehicleClass = '';
      scope.isExistingMappings = angular.isDefined(scope.isExistingMappings) ? scope.isExistingMappings : false;
      scope.filteredData = [];

      function setPagingData(page) {
        var pagedData = scope.filteredData.slice(
          (page - 1) * scope.pagination.itemsPerPage,
          page * scope.pagination.itemsPerPage
        );
        scope.visibleTransfers = pagedData;
        scope.pagination.currentPage = page;
      }
      scope.filterData = function () {
        var termToCheckDeparture = new RegExp(scope.filterByDepartureStation || '', 'i');
        var termToCheckArrival = new RegExp(scope.filterByArrivalStation || '', 'i');
        var termToCheckVehicleType = new RegExp(scope.filterByVehicleType || '', 'i');
        var termToCheckVehicleClass = new RegExp(scope.filterByVehicleClass || '', 'i');
        const departureStationName = scope.bookawaylines ? 'stationA.name' : 'fromStation.name';
        const arrivalStationsName = scope.bookawaylines ? 'stationB.name' : 'toStation.name';
        const departureStationAddress = scope.bookawaylines ? 'stationA.address' : 'fromStation.address';
        const arrivalStationAddress = scope.bookawaylines ? 'stationB.address' : 'toStation.address';
        const departureStationId = scope.bookawaylines ? 'stationA._id' : 'fromStation.id';
        const arrivalStationId = scope.bookawaylines ? 'stationB._id' : 'toStation.id';
        scope.filteredData = (scope.transfers || []).filter(function (transfer) {
          return (
            !scope.filterByDepartureStation ||
            StringHelperService.removeDiacritics(_.get(transfer, departureStationName, '').toString()).match(termToCheckDeparture) ||
            StringHelperService.removeDiacritics(_.get(transfer, departureStationId, '').toString()).match(termToCheckDeparture) ||
            StringHelperService.removeDiacritics(_.get(transfer, departureStationAddress, '').toString()).match(termToCheckDeparture)
          ) &&
            (
              !scope.filterByArrivalStation ||
              StringHelperService.removeDiacritics(_.get(transfer, arrivalStationsName, '').toString()).match(termToCheckArrival) ||
              StringHelperService.removeDiacritics(_.get(transfer, arrivalStationId, '').toString()).match(termToCheckArrival) ||
              StringHelperService.removeDiacritics(_.get(transfer, arrivalStationAddress, '').toString()).match(termToCheckArrival)
            ) &&
            (
              !scope.filterByVehicleType ||
              StringHelperService.removeDiacritics(_.get(transfer, 'vehicleType', '').toString()).match(termToCheckVehicleType)
            ) &&
            (
                !scope.filterByVehicleClass ||
                StringHelperService.removeDiacritics(_.get(transfer, 'vehicleClass', '').toString()).match(termToCheckVehicleClass)
            );
        });
      };

      scope.refresh = function () {
        scope.filterData();
        scope.pagination = {
          currentPage: 1,
          itemsPerPage: 8,
          totalItems: scope.filteredData.length
        };
        setPagingData(1);
      };

      scope.$watch('transfers', function () {
        scope.refresh();
      }, true);

      scope.$watch('filterByDepartureStation + filterByArrivalStation + filterByVehicleType + filterByVehicleClass', function () {
        scope.refresh();
      }, true);

      scope.$watch('currentPage', function () {
        setPagingData(scope.pagination.currentPage);
      });

      scope.pageChanged = function () {
        setPagingData(scope.pagination.currentPage);
      };

      scope.getMapLinkForAddress = function (query) {
        const encodedQuery = encodeURIComponent(query);
        const result = 'https://www.google.com/maps/search/' + encodedQuery;
        return result;
      };

      scope.clearFilter = function () {
        scope.filterByDepartureStation = '';
        scope.filterByArrivalStation = '';
        scope.filterByVehicleType = '';
        scope.filterByVehicleClass = '';
      };
    }
  };
}

'use strict';

/* exported
privateTransferService
 */

function privateTransferService(LineEditService, moment, uuid, $uibModal) {
    function getInterval() {
        return LineEditService.line.privateTransfer.interval.asMinutes();
    }

    function shiftDate(date) {
        return moment(date).add(1, 'day').format("YYYY-MM-DD");
    }

    function shiftPriceRuleDaysOfWeek(dows) {
        const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return dows.map(dow => {
          const id = dow.id === 6 ? 0 : dow.id + 1;
          return {
            id: id,
            label: labels[id],
          };
        });
    }

    function isTimeIntervalCrossDay(timeInterval) {
        return timeInterval.start >= timeInterval.end;
    }

    function setMultiStationsOffset(multiStations, offset) {
        const secondPrimary = multiStations.find(function (stationT) { return stationT.isPrimary && stationT.timeOffsetInMinutes > 0; });
        secondPrimary.timeOffsetInMinutes = offset;
    }

    function openCrossDayModal(nextDayStart) {
        return $uibModal.open({
          templateUrl: 'views/event/cross-day-modal.html',
          controller: 'CrossDayEventCtrl',
          resolve: {
            nextDayStart: [function () { return nextDayStart || "12:00AM"; }]
          }
        });
    }
  
    function minutesToTime(minutes) {
      const asMilliseconds = moment.duration(minutes, 'minutes').asMilliseconds();

      return moment.utc(asMilliseconds).format("HH:mm");
    }

    // calculate first departure in the next day of a cross-day interval as it is not necessarily 00:00.
    // Example: start = '22:00', interval = 90 => '01:00'
    function calculateNextDayStart(originalStart) {
        const midnight = moment.duration(24, 'hours');
        const interval = getInterval();
        const minutesFromStartToMidnight = midnight.asMinutes() - moment.duration(originalStart).asMinutes();        
        const nextDayStartMinutes = minutesFromStartToMidnight % interval && interval - (minutesFromStartToMidnight % interval);
        const nextDayStart = minutesToTime(nextDayStartMinutes);

        return nextDayStart;
    }

    this.splitCrossDayEvent = function(originalEvent, multiStations, pushEvent) {
        if (originalEvent.end.asHours() < 24) {
          return;
        }

        originalEvent.multiStations = multiStations;

        const nextDayStart = moment.duration(calculateNextDayStart(originalEvent.start));
        let nextDayEnd = originalEvent.end.subtract(24, 'hours');
      
        // original end was PT24H - for this edge case next day end should not be P0D - to allow for one departure at 00:00 the next day.
        if (originalEvent.end.asMinutes() === 0) {
          nextDayEnd = moment.duration(getInterval() - 1, 'minutes');
        }

        if (nextDayStart.asMinutes() < nextDayEnd.asMinutes()) {
          openCrossDayModal(moment.utc(nextDayStart.asMilliseconds()).format("hh:mmA")).result.then(function () {
            let nextDayEvent = Object.assign({}, _.cloneDeep(originalEvent));
            nextDayEvent = Object.assign(nextDayEvent, {
              id: uuid.v4(),
              start: nextDayStart,
              end: nextDayEnd,
              dow: originalEvent.dow.map(_dow => _dow === 6 ? 0 : _dow + 1),
              full: originalEvent.full.map(shiftDate),
              datesRange: originalEvent.datesRange.map(_datesRange => {
                const __datesRange = Object.assign({}, _datesRange);
                __datesRange.startDate = shiftDate(_datesRange.startDate);
                __datesRange.endDate = shiftDate(_datesRange.endDate);
              }),
            });
            delete nextDayEvent._id;
            setMultiStationsOffset(nextDayEvent.multiStations, nextDayEnd.asMinutes() - nextDayStart.asMinutes());
            pushEvent(nextDayEvent);
          });
        }

        originalEvent.end = moment.duration('23:59');
        setMultiStationsOffset(originalEvent.multiStations, originalEvent.end.asMinutes() - originalEvent.start.asMinutes());
    }

    this.splitPriceRules = function() {
        const newPriceRules = [];
        (LineEditService.line.priceExceptions || []).forEach(priceRule => {
          if (!priceRule.timeIntervals ||
            !priceRule.timeIntervals.length ||
            priceRule.timeIntervals.every(timeInterval => !isTimeIntervalCrossDay(timeInterval))) {
            return;
          }

          // extract only next-day portion (22:00-04:00 => 00:00-04:00) of cross-day time intervals to new price rule.
          // [14:00-20:00, 23:00-05:00, 22:00-00:45], interval=90 => [00:30-05:00]
          // explaination: first interval is not cross-day, second interval's first next-day departure is 00: 30, and third interval doesn't have next day departure.
          const newPriceRuleTimeIntervals = priceRule.timeIntervals
            .filter(isTimeIntervalCrossDay)
            .map(timeInterval => ({
              start: calculateNextDayStart(timeInterval.start),
              end: timeInterval.end !== '00:00' ? timeInterva.end : minutesToTime(getInterval()),
            }))
            .filter(timeInterval => !isTimeIntervalCrossDay(timeInterval));

          if (newPriceRuleTimeIntervals && newPriceRuleTimeIntervals.length) {
            const newPriceRule = _.cloneDeep(priceRule);
            newPriceRule.timeIntervals = newPriceRuleTimeIntervals;
            newPriceRule.dows = shiftPriceRuleDaysOfWeek(priceRule.dows);
            delete newPriceRule._id;
            newPriceRules.push(newPriceRule);
          }

          // cut cross-day time intervals to end at 23:59
          priceRule.timeIntervals.forEach(timeInterval => isTimeIntervalCrossDay(timeInterval) && (timeInterval.end = '23:59'));
        });

        if (LineEditService.line.priceExceptions) {
          LineEditService.line.priceExceptions.push(...newPriceRules);
        }

        LineEditService.refreshPriceRules();
    }
}
